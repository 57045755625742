//后端提供字典编码 name:字典名称，code：后端提供字典编码
const commonDict = {
  '100023': { name: '管线材质', code: '100023' },
  '100024': { name: '公称直径', code: '100024' },
  '100003': { name: '阀门种类', code: '100003' },
  '100019': { name: '供气场站', code: '100019' },
  '100032': { name: '用户场站', code: '100032' },
  '100001': { name: '管线点', code: '100001' },
  '100009': { name: '报警器分类', code: '100009' },
  '200002': { name: '报警器类型', code: '200002' },
  '100014': { name: '摄像头分类', code: '100014' },
  '100028': { name: '执行标准', code: '100028' },
  '100029': { name: '防腐方式', code: '100029' },
  '100004': { name: '敷设方式', code: '100004' },
  '100002': { name: '供电方式', code: '100002' },
  '100005': { name: '控制器类型', code: '100005' },
  '100006': { name: '连接方式', code: '100006' },
  '100007': { name: '开关控制模式', code: '100007' },
  '100027': { name: '防爆等级', code: '100027' },
  '100031': { name: '防护等级', code: '100031' },
  '100013': { name: '报警器类型', code: '100013' },
  '30011': { name: '管线确认状态', code: '30011' },
  '300034': { name: '标记类型', code: '300034' },
  '300001': { name: '阀门设施', code: '300001' },
  '300002': { name: '阀井类型', code: '300002' },
  
}

//常量
const constantDict = {
  jiedian: { name: '节点', dictCode: '0' },
  jiancedian: { name: '监测点', dictCode: '9000' },
  qitaguanjian: { name: '其他管件', dictCode: '25' }, //其他管件目前只有凝水缸，如有改动需要改sql
  guanxiandian: { name: '管线点', dictCode: '-1' },
  chonghejiedian: { name: '重合节点', dictCode: '9001' },
  xieloubaojing: { name: '泄漏报警', dictCode: '9002' },
  shexiangtou: { name: '摄像头', dictCode: '9003' },
  guanxian: { name: '管线', dictCode: '9004' },
  gongqizhan: { name: '供气站', dictCode: '1' },
  yonghuzhan: { name: '用户站', dictCode: '2' },
  gangsuzhuanhuan: { name: '钢塑转换', dictCode: '24' },
  santong: { name: '三通', dictCode: '22' },
  bianjin: { name: '变径', dictCode: '23' },
  jiancedianNew:{ name: '检测点', dictCode: '26' },
  sanfang:{ name: '三方施工', dictCode: '27' },
  yinbigongc:{ name: '隐蔽工程', dictCode: '28' },
  zhongdian:{ name: '重点设施', dictCode: '29' },
}

//图层名称
const commonLayer = {
  pressure: 'linqing:data-center-level',
  diameter: 'linqing:data-center-model',
  state: 'linqing:data-center-status',
  confirmstatus: 'linqing:data-center-confirmstatus',
  groupLayer: 'linqing:data-center-level', //图层组
  baseLine: 'linqing:tb_basic_pipe', //管线图层
  basePoint: 'linqing:tb_basic_point', //节点图层
  monitoPoint: 'linqing:tb_basic_monitor_point', //检测点图层
  groupPoint: 'linqing:group_point', //未闭合节点
  groupLine: 'linqing:group_pipe', //连通性分析
  40000: 'linqing:data-center-level',
  30010: 'linqing:data-center-status',
  30011: 'linqing:data-center-confirmstatus',
  texture: 'linqing:data-center-model',
  overlapping: 'linqing:t_point_overlapping', //重合节点
  queryOne: 'linqing:tb_basic_pipe_query',
  queryTwo: 'linqing:t_base_point_value_query',
  queryThree: 'linqing:t_base_point_station_query',
  queryFive:'	linqing:t_base_point_equipment_query',
  all_query: 'linqing:t_base_center',
  line_query:'linqing:tb_basic_pipe_new'
}
//枚举
const serverMap = {
  1001: [
    //仪表设备
    { id: 2, parentId: 1, name: '流量计', dictCode: '11', dictType: 1 },
    { id: 3, parentId: 1, name: '压力计', dictCode: '12', dictType: 1 }
  ],
  1002: [
    //阀门
    { id: 5, parentId: 4, name: '阀门', dictCode: '21', dictType: 1 }
  ],
  10031: [
    //管件
    // { id: 0, parentId: 4, name: '节点', dictCode: '0', dictType: 1 },
    // { id: 6, parentId: 4, name: '三通', dictCode: '22', dictType: 1 },
    { id: 7, parentId: 4, name: '变径', dictCode: '23', dictType: 1 },
    // { id: 8, parentId: 4, name: '钢塑转换', dictCode: '24', dictType: 1 }
    //  { id: 9, parentId: 4, name: '凝水缸', dictCode: '25', dictType: 1 }
    // { id: 9, parentId: 4, name: '其他管件', dictCode: '25', dictType: 1 }
  ],
  1003: [
    //管件
    // { id: 0, parentId: 4, name: '节点', dictCode: '0', dictType: 1 },
    // { id: 6, parentId: 4, name: '三通', dictCode: '22', dictType: 1 },
    { id: 7, parentId: 4, name: '变径', dictCode: '23', dictType: 1 },
    // { id: 8, parentId: 4, name: '钢塑转换', dictCode: '24', dictType: 1 },
    // { id: 9, parentId: 4, name: '凝水缸', dictCode: '25', dictType: 1 }
    // { id: 9, parentId: 4, name: '其他管件', dictCode: '25', dictType: 1 }
  ],
  1004: [
    //场站 dictCode这是字典查询用的，如果想用枚举，看上面的constantDict
    { id: 501, parentId: 489, name: '供气场站', dictCode: '100019', dictType: 1 },
    { id: 502, parentId: 489, name: '用户场站', dictCode: '100032', dictType: 1 }
  ],
  1005: [
    //安全设备
    { id: 11, parentId: 10, name: '泄漏报警器', dictCode: '31', dictType: 1 },
    { id: 12, parentId: 10, name: '摄像头', dictCode: '32', dictType: 1 }
  ],
  1006: [
    //压力级别
    //燃气集团 404- 411 共8类
     { id: 404, parentId: 403, name: '庭院', dictCode: 1, dictType: 1 },
    { id: 405, parentId: 403, name: '低压', dictCode: 2, dictType: 1 },
    { id: 406, parentId: 403, name: '中压B', dictCode: 3, dictType: 1 },
    { id: 407, parentId: 403, name: '中压A', dictCode: 4, dictType: 1 },
    { id: 408, parentId: 403, name: '次高压B', dictCode: 5, dictType: 1 },
    { id: 409, parentId: 403, name: '次高压A', dictCode: 6, dictType: 1 },
    { id: 410, parentId: 403, name: '高压B', dictCode: 7, dictType: 1 },
    { id: 411, parentId: 403, name: '高压A', dictCode: 8, dictType: 1 }
    //住建局只分下面三类
    // {"id": 412,"parentId": 403,"name": "中压","dictCode": "9","dictType": 1,},
    // {"id": 413,"parentId": 403,"name": "次高压","dictCode": "10","dictType": 1},
    // {"id": 414,"parentId": 403,"name": "高压","dictCode": "11","dictType": 1}
  ],
  1007: [
    //管线状态
    { id: 527, parentId: 525, name: '在用', dictCode: 2, dictType: 1 },
    { id: 526, parentId: 525, name: '在建', dictCode: 1, dictType: 1 },
    
    { id: 528, parentId: 525, name: '停用', dictCode: 3, dictType: 1 },
    { id: 529, parentId: 525, name: '报废', dictCode: 4, dictType: 1 },
    { id: 530, parentId: 525, name: '规划', dictCode: 5, dictType: 1 }
  ],
  1008: [
    { id: 14, parentId: 13, name: '天然气', dictCode: 1, dictType: 1 },
    { id: 15, parentId: 13, name: '焦炉气', dictCode: 2, dictType: 1 },
    { id: 16, parentId: 13, name: '液化石油气', dictCode: 3, dictType: 1 }
  ],
  1009: [
    // 状态 -压力计流量计
    { id: 34, parentId: 33, name: '停用', dictCode: 0, dictType: 1 },
    { id: 35, parentId: 33, name: '启用', dictCode: 1, dictType: 1 }
  ],
  1010: [
    // 是否远传
    { id: 34, parentId: 33, name: '否', dictCode: 0, dictType: 1 },
    { id: 35, parentId: 33, name: '是', dictCode: 1, dictType: 1 }
  ],
  1011: [
    // 是否在线
    { id: 34, parentId: 33, name: '否', dictCode: 0, dictType: 1 },
    { id: 35, parentId: 33, name: '是', dictCode: 1, dictType: 1 }
  ],
  1012: [
    // 场站的进出站节点
    { id: 34, parentId: 33, name: '进站', dictCode: 1, dictType: 1 },
    { id: 35, parentId: 33, name: '出站', dictCode: 2, dictType: 1 }
  ],
  1013: [
    // 删除元素
    { id: 34, parentId: 33, name: '管线', dictCode: 102, dictType: 1 },
    { id: 35, parentId: 33, name: '供气站', dictCode: 1, dictType: 1 },
    { id: 35, parentId: 33, name: '用户站', dictCode: 2, dictType: 1 },
    { id: 35, parentId: 33, name: '阀门', dictCode: 21, dictType: 1 },
    { id: 35, parentId: 33, name: '监测点', dictCode: 9000, dictType: 1 },
    { id: 35, parentId: 33, name: '检测点', dictCode: 26, dictType: 1 },
    { id: 35, parentId: 33, name: '三通', dictCode: 22, dictType: 1 },
    { id: 35, parentId: 33, name: '变径', dictCode: 23, dictType: 1 },
    { id: 35, parentId: 33, name: '隐蔽工程', dictCode: 28, dictType: 1 },
    { id: 35, parentId: 33, name: '塑钢转换', dictCode: 24, dictType: 1 },
    { id: 35, parentId: 33, name: '其他管件', dictCode: 25, dictType: 1 },
    { id: 35, parentId: 33, name: '管线点', dictCode: '-1', dictType: 1 },
    { id: 35, parentId: 33, name: '标记', dictCode: 100, dictType: 1 },
    { id: 35, parentId: 33, name: '草图', dictCode: 101, dictType: 1 },
    
  ],
  1014: [
    // 场站的进出站节点
    { id: 34, parentId: 33, name: '独立阀门', dictCode: 1, dictType: 1 },
    { id: 35, parentId: 33, name: '阀井', dictCode: 2, dictType: 1 },
    { id: 35, parentId: 33, name: '阀室', dictCode: 3, dictType: 1 }
  ],
  1015: [
    { id: 35, parentId: 33, name: '管线', dictCode: 11, dictType: 1 },
    { id: 34, parentId: 33, name: '供气站', dictCode: 1, dictType: 1 },
    { id: 35, parentId: 33, name: '用户站', dictCode: 2, dictType: 1 },
    { id: 35, parentId: 33, name: '阀门', dictCode: 3, dictType: 1 },
    { id: 34, parentId: 33, name: '监测点', dictCode: 4, dictType: 1 },
    { id: 35, parentId: 33, name: '检测点', dictCode: 5, dictType: 1 },
    { id: 35, parentId: 33, name: '三通', dictCode: 6, dictType: 1 },
    { id: 34, parentId: 33, name: '变径', dictCode: 7, dictType: 1 },
    { id: 35, parentId: 33, name: '钢塑转换', dictCode: 8, dictType: 1 },
    { id: 35, parentId: 33, name: '其他管件', dictCode: 9, dictType: 1 },
    { id: 35, parentId: 33, name: '管线点', dictCode: 10, dictType: 1 },
    
  ],
  1016: [
    // 删除元素
    { id: 34, parentId: 33, name: '管线', dictCode: 102, dictType: 1 },
    { id: 35, parentId: 33, name: '供气站', dictCode: 1, dictType: 1 },
    { id: 35, parentId: 33, name: '用户站', dictCode: 2, dictType: 1 },
    { id: 35, parentId: 33, name: '阀门', dictCode: 21, dictType: 1 },
    { id: 35, parentId: 33, name: '监测点', dictCode: 9000, dictType: 1 },
    { id: 35, parentId: 33, name: '检测点', dictCode: 26, dictType: 1 },
    { id: 35, parentId: 33, name: '三通', dictCode: 22, dictType: 1 },
    { id: 35, parentId: 33, name: '变径', dictCode: 23, dictType: 1 },
    { id: 35, parentId: 33, name: '隐蔽工程', dictCode: 28, dictType: 1 },
    { id: 35, parentId: 33, name: '塑钢转换', dictCode: 24, dictType: 1 },
    { id: 35, parentId: 33, name: '其他管件', dictCode: 25, dictType: 1 },
    { id: 35, parentId: 33, name: '管线点', dictCode: '-1', dictType: 1 },
    { id: 35, parentId: 33, name: '标记', dictCode: 100, dictType: 1 },
    { id: 35, parentId: 33, name: '草图', dictCode: 101, dictType: 1 },
    
  ],
}

//默认选中图层 供气站，用户站，阀门
const initFilter = [
  constantDict.gongqizhan.dictCode,
  constantDict.yonghuzhan.dictCode,
  serverMap['1002'][0].dictCode
]

export { commonDict, commonLayer, serverMap, constantDict, initFilter }
