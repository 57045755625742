<template>
  <div>
    <div class="collapseDiv">
      元件属性
    </div>
    <div class="f-div">
      <el-form label-position="left" label-width="85px" :model="selectSvgInfo" size="small">
        <el-form-item label="元件标识">
          <el-input v-model="selectSvgInfo.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="元件类型">
          <el-input v-model="selectSvgInfo.title" disabled></el-input>
        </el-form-item>
        <!-- <el-form-item label="元件类型">
          <el-input v-model="selectSvgInfo.type" disabled></el-input>
        </el-form-item> -->
        <el-form-item v-if="getCollection()" label="元件名称">
          <el-input v-model="selectSvgInfo.name"></el-input>
        </el-form-item>
        <el-form-item label="X轴坐标">
          <el-input-number
            v-model="selectSvgInfo.svgPositionX"
            controls-position="right"
            @change="setBox()"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="Y轴坐标">
          <el-input-number
            v-model="selectSvgInfo.svgPositionY"
            controls-position="right"
            @change="setBox()"
          ></el-input-number>
        </el-form-item>
        <el-form-item v-if="getType()" label="宽度比">
          <el-input-number
            v-model="selectSvgInfo.sizeW"
            controls-position="right"
            :precision="2"
            @change="setBox()"
          ></el-input-number>
        </el-form-item>
        <el-form-item v-if="getType()" label="高度比">
          <el-input-number
            v-model="selectSvgInfo.sizeH"
            controls-position="right"
            :precision="2"
            @change="setBox()"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="旋转角度">
          <el-input-number
            v-model="selectSvgInfo.angle"
            controls-position="right"
            :precision="0"
            @change="setBox()"
          ></el-input-number>
        </el-form-item>

        <template v-if="!eqTypeArray.includes(selectSvgInfo.type)">
          <!-- 类型是设备（流量计、压力计、泄漏报警器、温度计）时放到下面显示 -->
          <div v-for="(item, index) in selectSvgInfo.extend_attr" :key="index">
            <el-form-item v-if="item.type" :label="item.title">
              <el-input-number
                v-if="item.type == 'numberinputbox' && item.title == '标题字大小'"
                v-model="item.val"
                controls-position="right"
                @change="changeFontSize1"
              ></el-input-number>
              <el-input-number
                v-else-if="item.type == 'numberinputbox' && item.title == '列表字大小'"
                v-model="item.val"
                controls-position="right"
                @change="changeFontSize2"
              ></el-input-number>
              <el-input-number
                v-else-if="item.type == 'numberinputbox'"
                v-model="item.val"
                controls-position="right"
              ></el-input-number>
              <el-input v-else-if="item.type == 'textinputbox'" v-model="item.val"></el-input>
              <el-input
                v-else-if="item.type == 'disableinputbox'"
                v-model="item.val"
                :disabled="true"
              ></el-input>
              <el-row v-else-if="item.type == 'colorinputbox'">
                <el-col :span="19"><el-input v-model="item.val"></el-input></el-col>
                <el-col :span="5"
                  ><el-color-picker
                    v-model="item.val"
                    show-alpha
                    color-format="hex"
                  ></el-color-picker
                ></el-col>
              </el-row>
              <el-row v-else-if="item.type == 'file'">
                <el-col :span="14"><el-input v-model="item.val"></el-input></el-col>
                <el-col :span="10">
                  <el-upload
                    class="upload-demo"
                    action="#"
                    :auto-upload="true"
                    :http-request="
                      e => {
                        return repairBeforUploadFun(item, e)
                      }
                    "
                    :show-file-list="false"
                    accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                    multiple
                  >
                    <el-button size="small" type="primary">上传</el-button>
                  </el-upload>
                </el-col>
              </el-row>
              <el-select
                v-else-if="item.type == 'select'"
                v-model="item.val.selectval"
                placeholder="请选择"
              >
                <el-option
                  v-for="itemsub in item.val.selectgroup"
                  :key="itemsub.value"
                  :label="itemsub.label"
                  :value="itemsub.value"
                >
                </el-option>
              </el-select>
              <el-select
                v-else-if="item.type == 'select_plevel'"
                v-model="item.val.selectval"
                placeholder="请选择"
                @change="setPipeColor($event, item.val)"
              >
                <el-option
                  v-for="itemsub in item.val.selectgroup"
                  :key="itemsub.value"
                  :label="itemsub.label"
                  :value="itemsub.value"
                >
                </el-option>
              </el-select>
              <!-- 设备 采集点 为联动关系 -->
              <!-- <el-select
                v-else-if="item.type == 'select_Interface' && item.title === '关联设备'"
                v-model="item.val"
                placeholder="请选择"
                filterable
                @change="changeEquiment"
              >
                <el-option
                  v-for="itemsub in equipmentList"
                  :key="itemsub.equipCode"
                  :label="itemsub.equipName"
                  :value="itemsub.equipCode"
                >
                </el-option>
              </el-select>
              <el-select
                v-else-if="item.type == 'select_Interface' && item.title === '采集点'"
                v-model="item.val"
                placeholder="请选择"
                filterable
                @change="changePoint"
              >
                <el-option
                  v-for="itemsub in collectionPointList"
                  :key="itemsub.pointId"
                  :label="itemsub.pointName"
                  :value="itemsub.pointId"
                >
                </el-option>
              </el-select>
              <el-select
                v-else-if="item.type == 'select_Interface' && item.title === '阀门名称'"
                v-model="item.val"
                placeholder="请选择"
                filterable
                @change="changeValve"
              >
                <el-option
                  v-for="itemsub in valveList"
                  :key="itemsub.equipId"
                  :label="itemsub.equipName"
                  :value="itemsub.equipId"
                >
                </el-option>
              </el-select> -->
            </el-form-item>
          </div>
        </template>
      </el-form>
    </div>
    <template v-if="eqTypeArray.includes(selectSvgInfo.type)">
      <!-- 类型是设备（流量计、压力计、泄漏报警器、温度计）时显示，其它类型都是在元件属性下面，这个分开了，不知道为啥，反正就和别的不统一 -->
      <div class="collapseDiv">
        关联设备
      </div>
      <div class="f-div">
        <el-form label-position="left" label-width="85px" :model="selectSvgInfo" size="small">
          <div v-for="(item, index) in selectSvgInfo.extend_attr" :key="index">
            <el-form-item v-if="item.type" :label="item.title">
              <el-input-number
                v-if="item.type == 'numberinputbox'"
                v-model="item.val"
                controls-position="right"
              ></el-input-number>
              <el-input v-else-if="item.type == 'textinputbox'" v-model="item.val"></el-input>
              <el-input
                v-else-if="item.type == 'disableinputbox'"
                v-model="item.val"
                :disabled="true"
              ></el-input>
              <el-row v-else-if="item.type == 'colorinputbox'">
                <el-col :span="19"><el-input v-model="item.val"></el-input></el-col>
                <el-col :span="5"
                  ><el-color-picker
                    v-model="item.val"
                    show-alpha
                    color-format="hex"
                  ></el-color-picker
                ></el-col>
              </el-row>
              <el-select
                v-else-if="item.type == 'select'"
                v-model="item.val.selectval"
                placeholder="请选择"
              >
                <el-option
                  v-for="itemsub in item.val.selectgroup"
                  :key="itemsub.value"
                  :label="itemsub.label"
                  :value="itemsub.value"
                >
                </el-option>
              </el-select>
              <!-- 设备 选择设备后自动带出采集点-->
              <el-select
                v-else-if="item.type == 'select_Interface' && item.title === '设备名称'"
                v-model="item.val"
                placeholder="请选择"
                filterable
                clearable
                @change="changeEqGetCoList"
              >
                <el-option
                  v-for="itemsub in equipmentList"
                  :key="itemsub.equipCode"
                  :label="itemsub.equipName"
                  :value="itemsub.equipCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </template>
  </div>
</template>
<script>
import { GenUUidByType } from '@/utils/UCore.js'
// import { uploadImg } from '@/apis/svg.js'
import scadaApi from "@/apis/scadaApi";
import contractApi from "@/apis/contractApi";
export default {
  props: ['selectSvgInfo', 'toolTypeArray', 'boxRight'],
  data() {
    return {
      // 采集点列表
      collectionPointList: [],
      // 设备列表
      equipmentList: [],
      // 阀门列表
      valveList: [],
      stationId: null,
      // 类型是设备，用来判断右侧属性栏显示样式，设备的时候显示关联设备
      eqTypeArray: ['flowmeter', 'manometer', 'leakAlarm', 'thermometer']
    }
  },
  watch: {
    /**************************************
     * @description		当选中关联采集点或阀门的值时，更换选择列表
     * @author			lzj
     * @date			2022-09-13
     * @updata    2023-03-28 添加设备概念与采集点联动
     * @updata    2023-09-22 单纯针对设备，下拉选择设备，选择设备自动关联出采集点
     **************************************/
    selectSvgInfo() {
      // if (this.boxRight == 0) {
      return
      let newval = this.selectSvgInfo
      if (!newval.id) return
      const _this = this
      let getListTabele = [
        'totalTiredness',
        'conditioning',
        'pressureCollection',
        'concentrationCollection',
        'temperatureCollection',
        'totalTiredConditions',
        'instantaneousService'
      ]
      let valveTypeList = [
        'ballValve',
        'flowControlValve',
        'gateValve',
        'globeValve',
        'checkValve',
        'bleedValve'
      ]
      // (11,"流量计") 12(12,"压力计") 14(14,"温度计") 21(21,"阀门") 31(31,"泄漏报警器")
      let equipmentCodeList = ['11', '11', '11,12,14', '31', '11,12,14', '11', '11']
      if (newval.type) {
        let index = getListTabele.indexOf(newval.type)
        let stationId = this.$parent.$refs.topToolBar.fileData.stationId
        if (index !== -1) {
          // 2023/3/28 采集点：请求设备 --》 相关采集点绑定
          let equipmentCode = equipmentCodeList[index]
          scadaApi.findEquipOfStation({ equipType: equipmentCode, stationId }).then(res => {
            if (res.code == 200) {
              _this.equipmentList = res.data
            }
          })

          // 如果保存过则请求采集点列表
          if (newval.extend_attr.f_eqtype.val) {
            _this.changeEquiment(newval.extend_attr.f_eqtype.val, true)
          } else {
            // 清空子选项
            _this.collectionPointList = []
          }
        } else if (valveTypeList.indexOf(newval.type) !== -1) {
          // 2023/3/28 阀门:直接请求设备接口
          // let equipType = newval.type == 'checkValve' ? 21 : 21
          let equipType = 21
          scadaApi.findEquipOfStation({ equipType, stationId }).then(res => {
            if (res.code == 200) {
              _this.valveList = res.data
            }
          })
        } else if (this.eqTypeArray.indexOf(newval.type) !== -1) {
          // 2023/9/22 设备:直接请求设备接口
          let index = this.eqTypeArray.indexOf(newval.type)
          let eqCodeList = [11, 12, 31, 14]
          let equipTypeStr = eqCodeList[index]
          scadaApi.getStationEquipByType({ equipTypeStr, stationId }).then(res => {
            if (res.code == 200) {
              _this.equipmentList = res.data
            }
          })
        }
      }
      // }
    }
  },
  mounted() {
    this.stationId = this.$parent.$refs.topToolBar.fileData.stationId
  },
  methods: {
    async repairBeforUploadFun(item, e) {
      const that = this
      let repairBeforFileFormData = new FormData()
      repairBeforFileFormData.append('file', e.file)
      repairBeforFileFormData.append('modelName', '测试')
      await contractApi.uploadImg(repairBeforFileFormData).then(res => {
        item.val = res.data.url
        var imageObj = new Image()
        imageObj.src = item.val
        imageObj.onload = function() {
          that.selectSvgInfo.extend_attr.width = imageObj.width
          that.selectSvgInfo.extend_attr.height = imageObj.height
        }
      })
    },
    //关联采集点不显示的属性
    getCollection() {
      let getListTabele = [
        'totalTiredness',
        'conditioning',
        'pressureCollection',
        'concentrationCollection',
        'temperatureCollection',
        'totalTiredConditions',
        'instantaneousService',
        'module',
        'skid',
        'collectPoint'
      ]
      let haves = getListTabele.indexOf(this.selectSvgInfo.type)
      if (haves != -1) {
        return false
      } else {
        return true
      }
    },
    setPipeColor(e, item) {
      this.selectSvgInfo.extend_attr.o_color.val = item.selectgroup[e].pipe_color
      this.selectSvgInfo.extend_attr.i_color.val = item.selectgroup[e].flow_color
    },
    setBox() {
      this.$eventBus.$emit('updataSelectB')
    },
    getType() {
      let haves = this.toolTypeArray.indexOf(this.selectSvgInfo.type)
      if (haves != -1) {
        return false
      } else {
        return true
      }
    },
    changeEquiment(e, b) {
      // 请求采集点
      let that = this
      if (e) {
        let getListTabele = [
          'totalTiredness',
          'conditioning',
          'pressureCollection',
          'concentrationCollection',
          'temperatureCollection',
          'totalTiredConditions',
          'instantaneousService'
        ]
        let dataTypeList = [
          3000810002,
          3000810003,
          3000810005,
          3000810006,
          3000810007,
          3000810001,
          3000810004
        ]
        let index = getListTabele.indexOf(that.selectSvgInfo.type)
        let [dataTypeCode, stationId] = [
          dataTypeList[index],
          that.$parent.$refs.topToolBar.fileData.stationId
        ]
        scadaApi.findPointOfStation({ dataTypeCode, stationId, eqCode: e }).then(res => {
          if (res.code == 200) {
            that.collectionPointList = res.data
          }
        })

        if (!b) {
          that.selectSvgInfo.extend_attr.f_eqvalue.val = that.selectSvgInfo.title + '值'
          that.selectSvgInfo.extend_attr.f_valuesize.val = 8
          that.selectSvgInfo.extend_attr.v_eqid.val = ''
          that.selectSvgInfo.extend_attr.v_equiment.val = ''
          that.selectSvgInfo.extend_attr.v_eqname.val = that.selectSvgInfo.title
        }
      }
    },
    changePoint(e) {
      if (e) {
        let [val, str] = ['', '']
        let title = this.selectSvgInfo.title
        this.collectionPointList.forEach(item => {
          if (item.pointId == e) {
            if (title == '总累标况' || title == '总累工况') {
              val = item.totalVal + item.dataUnit
            } else {
              val = item.currVal + item.dataUnit
            }
            // let nameArr = item.pointName.split('#')
            str = item.pointName
            // str = '#' + nameArr[nameArr.length - 1]
          }
        })
        this.selectSvgInfo.extend_attr.v_eqname.val = str //采集点名称 2023/3/28绑定id
        this.selectSvgInfo.extend_attr.f_eqvalue.val = val //采集点值
        this.selectSvgInfo.extend_attr.f_valuesize.val = this.getStrLength(str) //采集点值长度
      } else {
        this.selectSvgInfo.extend_attr.f_eqvalue.val = ''
        this.selectSvgInfo.extend_attr.f_valuesize.val = 8
      }
      // this.changeValve(e)
      this.selectSvgInfo.extend_attr.v_eqid.val = e
    },
    changeValve(e) {
      this.selectSvgInfo.extend_attr.v_eqid.val = e
      // 绑定阀门会变绿色 2023/3/28 阀门svg图更换 去掉颜色
      // this.selectSvgInfo.extend_attr.svg_color.val = 'rgba(154, 239, 161, 1)'
    },
    /**
     * 选择设备，自动带出采集点
     */
    changeEqGetCoList(e) {
      let _this = this
      if (e) {
        let stationId = this.stationId
        let eqObjList = this.equipmentList.filter(m => {
          return m.equipCode == e
        })
        let equipCode = e
        let eqName = eqObjList[0].equipName
        scadaApi.getPointsByEquipCode({ equipCode, stationId }).then(res => {
          if (res.code == 200) {
            if (res.data.length > 0) {
              let module = this.$parent.svgInfoData.filter(m => {
                return m.data_type == 'module'
              })
              let mo = JSON.parse(JSON.stringify(module[0])) //组模板
              let f_list = JSON.parse(JSON.stringify(res.data)) //采集点列表
              // 计算所有采集点名称、采集点值、单位 的最长的长度
              let pointNameArry = []
              let valueArry = []
              let unitArry = []
              f_list.forEach(el => {
                // 这里加冒号是模板里采集点名称有冒号，不加的话长度计算不对
                pointNameArry.push(
                  _this.$parent.getActualWidthOfChars(
                    el.pointName + '：',
                    mo.extend_attr.f_content_size.val,
                    400
                  )
                )
                valueArry.push(
                  _this.$parent.getActualWidthOfChars(
                    el.currVal,
                    mo.extend_attr.f_content_size.val,
                    700
                  )
                )
                unitArry.push(
                  _this.$parent.getActualWidthOfChars(
                    el.dataUnit,
                    mo.extend_attr.f_content_size.val,
                    400
                  )
                )
              })
              let maxPname = Math.max(...pointNameArry)
              let maxValue = Math.max(...valueArry)
              let maxUnit = Math.max(...unitArry)
              let groupLength = _this.$parent.getActualWidthOfChars(
                eqName,
                mo.extend_attr.f_title_size.val,
                700
              )

              let width = (maxPname + maxValue + maxUnit + 97) / 2 //采集点矩形框宽度
              let rect1Height =
                _this.$parent.getActualHeightOfChars(eqName, mo.extend_attr.f_title_size.val) + 16 // 标题矩形框高度
              let rect2Height =
                (_this.$parent.getActualHeightOfChars(eqName, mo.extend_attr.f_content_size.val) +
                  10) *
                  f_list.length +
                20 // 采集点矩形框高度
              let height = (rect1Height + rect2Height + 10) / 2
              let oldSelectInfo = JSON.parse(JSON.stringify(_this.selectSvgInfo)) //选中设备对应的svg
              let svgPositionX =
                oldSelectInfo.svgPositionX + oldSelectInfo.extend_attr.width - width
              let svgPositionY =
                oldSelectInfo.svgPositionY + oldSelectInfo.extend_attr.width * 2 + 20

              mo.extend_attr.f_list = f_list // 采集点列表
              mo.extend_attr.f_title_name = eqName //组标题
              mo.extend_attr.f_content_width1 = maxPname // 采集点名称的最大长度
              mo.extend_attr.f_content_width2 = maxValue // 采集点值的最大长度
              mo.extend_attr.f_content_width3 = maxUnit // 单位的最大长度
              mo.extend_attr.f_title_width = groupLength // 组名称长度
              mo.extend_attr.width = width
              mo.extend_attr.height = height

              let CurrentlySelectedToolBar = {
                Type: 'module', //选中的工具栏svg类型
                TypeName: '组', //选中的工具栏svg类型名称
                Title: '组', //选中的工具栏svg标题
                Identity: '99', //选中的工具栏svg标题
                CreateType: 'draggable', //选中工具栏的创建方式
                extend_attr: mo.extend_attr
              }
              this.$store.commit('zt/setCurrentlySelectedToolBarAction', CurrentlySelectedToolBar)
              let svg_id = GenUUidByType(mo.identity, 8, 16)
              let svgItem = {
                id: svg_id,
                title: this.$store.getters.CurrentlySelectedToolBar.Title,
                type: this.$store.getters.CurrentlySelectedToolBar.Type,
                typeName: this.$store.getters.CurrentlySelectedToolBar.TypeName,
                extend_attr: mo.extend_attr,
                svgPositionX: svgPositionX,
                svgPositionY: svgPositionY,
                echartsOption: '',
                sizeW: 1,
                sizeH: 1,
                angle: 0
              }

              this.$parent.$refs.baseSvg.svgLists.push(svgItem)
              this.$parent.selectSvgInfo = svgItem
              this.$parent.$refs.baseSvg.selectSvg.ID = svg_id
              this.$parent.$refs.baseSvg.selectSvg.Index =
                this.$parent.$refs.baseSvg.svgLists.length - 1
              this.$parent.$refs.baseSvg.clickType = ''
              this.$parent.$refs.baseSvg.drageState = false
              _this.$nextTick(() => {
                _this.$eventBus.$emit('drawChange', [
                  _this.$parent.$refs.baseSvg.svgLists,
                  _this.$parent.$refs.baseSvg.selectSvg
                ])
                _this.$parent.$refs.baseSvg.initGroup()
                _this.$parent.$refs.baseSvg.updataSelectB()
                _this.$parent.radio = 1
                _this.$parent.pointChecked = true
                _this.$parent.isGroupCollect = true
              })
            } else {
              this.$message.warning('该设备下没有绑定采集点')
            }
          }
        })
      }
      this.selectSvgInfo.extend_attr.eq_code.val = e
    },
    getStrLength(str) {
      ///获得字符串实际长度，中文2，英文1
      ///要获得长度的字符串
      var realLength = 0,
        len = str.length,
        charCode = -1
      for (var i = 0; i < len; i++) {
        charCode = str.charCodeAt(i)
        if (charCode >= 0 && charCode <= 128) realLength += 1
        else realLength += 2
      }
      return realLength
    }

    // changeFont(key){
    //   if(key=='f_size'){

    //   }
    // }
  }
}
</script>
<style lang="scss" scoped>
:deep() {
  //颜色选择框高度跟其他input不一致，这里设置成0
  .el-form-item--small .el-form-item__content {
    line-height: 0px;
  }
}
.el-input-number--small {
  width: 100%;
}
.collapseDiv {
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  padding: 0 10px;
  background: var(--background-top-title-skin);
  color: var(--background-top-font-skin);
  box-shadow: 0px 1px 0px 0px rgba(209, 209, 209, 0.45);
  cursor: pointer;
  i {
    line-height: 36px;
    float: right;
  }
}
.f-div {
  margin: 20px;
}
</style>
