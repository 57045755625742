<template>
  <div class="patrolLine" onselectstart="return false;" >
    <div class="warningT">
      <div class="marquee_box">
        <div id="marquee_left">
          <p>
            热烈欢迎各位领导莅临指导工作
          </p>
          <p>
            热烈欢迎各位领导莅临指导工作
          </p>
          <p>
            热烈欢迎各位领导莅临指导工作
          </p>
          <p>
            热烈欢迎各位领导莅临指导工作
          </p>
        </div>
      </div>
    </div>
    <div class="box"><p>中联燃气智慧管理平台</p></div>
    <div class="">

    </div>
    <div class="mapLeft" :class="{ 'hidden': !open }">
      <div class="map-title">
        <div class="tabs">
          <div class="tab" @click="handleClick(1)" :class="{ 'actie': pageParams.installType == 1 }">场站</div>
          <div class="tab" @click="handleClick(2)" :class="{ 'actie': pageParams.installType == 2 }">管网</div>
        </div>
        <div style="cursor: pointer;color:#fff" @click="open = !open">
          <i v-if="open" class="el-icon-arrow-up"></i>
          <i v-else class="el-icon-arrow-down"></i>
        </div>
      </div>
      <div class="list-box" v-if="open">
        <div class="list" v-for="(item, index) in list" :key="item.id" :class="{ 'activeClick': selected == item.id }"
          @click="onClickList(item, 1,index)">
          <div>
            报警器编码：<span>{{ item.equipmentCode }}</span>
          </div>
          <div>
            报警器名称：<span>{{ item.equipmentName }}</span>
          </div>
          <div style="display: flex;justify-content: space-between;">
            <div>
              报警值：<span>{{ item.val || item.val ? item.val : '-' }}</span>
            </div>
            <div>
              <el-button size="mini" @click.stop="onDialog(item.id)"
                style="background: rgba(255,0,0,0.2);color: #FFE3E3; border: 0;">处理</el-button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="open">
        <div class="pages">
          <el-pagination small :current-page="pageParams.current" :page-size="pageParams.size" :total="total"
            @current-change="currentChange" layout="total, prev, pager, next"  :pager-count="6">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" width="470px" class="bounddialog">
      <template slot="title">
        <div class="dialogTitles">
          <span></span> <b>告警处置</b>
        </div>
      </template>
      <el-form v-if="dialogVisible" :model="formParams" class="demo-form-inline" label-position="right">
        <el-form-item label="备注">
          <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="formParams.handleNote">
          </el-input>
        </el-form-item>
      </el-form>
      <div class="search-q" style="text-align: center;margin-top: 20px;">
        <el-button type="info" plain @click="dialogVisible = false" size="mini">取消</el-button>
        <el-button type="primary" @click="submit()" size="mini">确定</el-button>
      </div>
    </el-dialog>
    <left ref="left" />
    <right ref="right" />
    <div class="mapRight" ref="mapRightRef" id="mapRightId">
          <subMap ref="subMap"  @markerClick="onClickList" @warnHandle="onDialog" :warnList="mapList" :modeal="'1'"/>  
    </div>
    <audio ref="audio" hidden src="../../assets/warning.mp3" loop></audio>
  </div>
</template>
<script>
import left from './biLeft/index'
import right from './biRight/index'
// import subMap from '@/views/mapTool/subMapbi'
import subMap from '@/views/Gis/gisbi'
import { mapGetters, mapState } from 'vuex'
import { warningPage,handleWarning } from '@/apis/warn/alarmManagement/pendingAlarm'

export default {
  name: 'PatrolHouse',
  components: { left, right ,subMap},
  props: {},
  data: () => ({
    currentArea: null,
    bubbles: null,
    chart: null,
    MenuArray:{},
    open: true,
    dialogVisible: false,
    pageParams: {
      current: 1,
      size: 100,
      queryType: 1,
      installType: 2,
      equipmentType: 4
    },
    mapList: [],
    list: [],
    formParams: {},
    total: 0,
    selected: null,
    warnTotal:0
  }),
  computed: {
    ...mapState('bottom', ['featureMap']),
    ...mapState('dict', ['mainAreaDict'])
  },
  watch: {
    '$store.getters.fullState'(val) {
      console.log(val)
      let rightTool = document.getElementsByClassName('rightTool')[0]
      if (val) {
        rightTool.style.right = '4.22rem'
      } else {
        rightTool.style.right = '0'
        // this.postionVal = -4.52
      }
    },
  },
  destroyed(){
    document.body.classList.remove('black')
    if(this.$refs.audio){
            this.$refs.audio.pause()
        }
  },
  created() {},
  mounted() {
    this.MenuArray = JSON.parse(localStorage.getItem('MenuTree'))
    if(this.MenuArray){
      let exist = false
      this.MenuArray.forEach((item,index)=>{
        if(item.url == 'BI') {
          exist = true
        }
      })
      if(!exist) {
       this.$router.push("./index");
      }
    }
    window.onresize = () => {
      let state = this.$store.getters.onresize
      this.$store.commit('gis/onresizeChange', !state)
    }
    this.$nextTick(()=>{
        let rightTool = document.getElementsByClassName('rightTool')[0]
        rightTool.style.right = '4.22rem'
    })
    
    this.getDatas()
    this.loadList(this.pageParams)
    this.getAllWarnTotal()
  },
  methods: {
    getAllWarnTotal(){
      warningPage({current: 1,size: 0,queryType: 1,equipmentType: 4}).then(res => {
        if(res.code == 200){
          this.warnTotal = res.data.total
          if(res.data.total>0){
            this.$refs.audio.play()
          }else{
            this.$refs.audio.pause()
          }
        }
      })
    },
    handleClick(e) {
      this.list = []
      this.pageParams = {
        current: 1,
        size: 100,
        queryType: 1,
        equipmentType: 4
      }
      if (this.pageParams.installType != e) {
        this.pageParams.installType = e
      }
      this.loadList(this.pageParams)
    },
    onClickList(e, type,index) {
      this.selected = e.id
      if (type == 1 && e.point) {
        let str = (e.point).slice(6, -1)
        let arr = str.split(' ')
        console.log(arr)
        this.$refs.subMap.setZoom(arr,index)
      }
    },
    currentChange(current) {
      this.selected = null;
      this.pageParams.current = current
      this.loadList(this.pageParams)
    },
    loadList(data) {
      let obj = JSON.parse(JSON.stringify(data))
      obj.handleStatus = 0
      warningPage(obj).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.total = res.data.total
          let mapList = this.list.map(el => {
            if (el.point) {
              let str = (el.point).slice(6, -1)
              let arr = str.split(' ')
              let obj = {
                id: el.id,
                title: el.equipmentName,
                position: arr,
                data: el
              }
              return obj
            } else {
              return
            }
          })
          this.mapList = mapList.filter(el => el != null)
        }
      })
    },
    onDialog(id) {
      console.log(id)
      this.formParams = {
        handleNote: "",
        id
      }
      this.dialogVisible = true
    },
    submit() {
      handleWarning(this.formParams).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.dialogVisible = false
          this.loadList(this.pageParams)
          this.getAllWarnTotal()
        } else {
          // this.dialogVisible = false
          this.$message.error(res.msg)
          // this.loadList(this.pageParams)
        }
      })
    },
    async getDatas(){
      let _this = this
      await _this.$refs.left.getlineData()
      // await _this.$refs.left.getEquipmentData()

      // await _this.$refs.right.getTaskList()
      await _this.$refs.left.setScroll()
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-pager li {
  color: #FFFFFF;
  background: #47080C;
}

::v-deep .el-pagination .btn-prev {
  color: #FFFFFF;
  background: #47080C;
}

::v-deep .el-pagination .btn-next {
  color: #FFFFFF;
  background: #47080C;
}

::v-deep .el-pagination__total {
  color: #FFFFFF;
}
.patrolLine{
  height: calc(100vh - 1rem);
  margin-top: 1rem;
  /* height: 100vh; */
  position: relative;
}
.warningT {
  position: fixed;
  top: 0;
  left: 0;
  font-size: 0.22rem;
  color: #D42517;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  height: 1rem;
  background: #000;
}
.marquee_box {
  width: 100vw;
  overflow: hidden;
}
#marquee_left {
  /* margin-left: 100vw; */
  animation: infinite 15s left linear;

}
#marquee_left > p {
  font-size: 0.6rem;
  font-weight: bold;
  display: inline-block;
  width: 70vw;
  letter-spacing: 6px;
}
#marquee_left:hover {
  animation-play-state: paused;
}
@keyframes left {
  from {
    transform: translateX(0);
  }
  to {
    /* transform: translateX(-130vw); */
    transform: translateX(calc(-140vw));
  }
}
.box {
  z-index: 5;
  width: 100%;
  text-align: center;
  color: #ffffff;
  line-height: 1rem;
  height: 1.58rem;
  position: fixed;
  background: url('../../assets/br/bg.png') center center;
  background-size: 100% 100%;
  p{
    font-size: 0.47rem;
    background: linear-gradient(0deg, #D8E4FF 0%, #FFFFFF 99.0478515625%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }
}
.mapLeft {
  position: absolute;
  left: 4.5rem;
  top: 1.1rem;
  width: 325px;
  // height: 689px;
  height: calc(100% - 400px);
  min-height: 400px;
  background: rgba(40, 4, 4, 0.96);
  border: 2px solid #BC1414;
  box-shadow: 0px 0px 6px #BC1414;
  border-radius: 4px;
  z-index: 200;

  &.hidden {
    height: auto;
    min-height: 0;
  }

  .map-title {
    margin: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(90deg, #BC1414 0%, rgba(188, 20, 20, 0) 100%);
    padding-right: 10px;

    .tabs {
      display: flex;

      .tab {
        padding: 3px 0;
        margin: 0 15px;
        cursor: pointer;
        color: #FFAEAE;

        &.actie {
          color: #fff;
          border-bottom: 1px solid #fff;
        }
      }
    }
  }

  .list-box {
    padding: 0 14px;
    height: calc(100% - 95px);
    overflow: auto;

    .list {
      padding: 10px 6px;
      color: #FFE3E3;
      border-bottom: 1px solid #4C080C;
      cursor: pointer;

      &.activeClick {
        color: #fff;
        background: linear-gradient(90deg, #BC1414 0%, rgba(188, 20, 20, 0) 100%);
      }
    }
  }

  .pages {
    position: absolute;
    bottom: 0;
  }
}
.mapRight{
  width: auto;
  margin-left:0;position: relative;overflow: hidden;
  /* height:calc(91vh); */
  height:calc(100vh);

}
.separation {
  position: absolute;
  top: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 0.15rem;
  font-weight: 400;
  color: #74beea;
  .separation_first {
    cursor: pointer;
    width: 1.45rem;
    height: 0.32rem;
    background: #172940;
    border: 1px solid #73f7ff;
  }
  .separation_first:first-child {
    border-right: 0;
  }
  .separation_pitch {
    background: rgba(0, 246, 255, 0.16);
    color: #ffffff;
  }
}
.drop-down {
  position: absolute;
  top: 0.8rem;
  right: 0.2rem;
}
</style>
