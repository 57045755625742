<template>
  <div>
    <!-- <div ref="selectFeatures" class="Bubble">
      <div class="markbox">
        <div class="markTitle">
          <p>
            <span>请选择要查看的管线设备</span>
          </p>
          <i class="el-icon-error" @click="closeWindow()"></i>
        </div>
        <div class="mark-con">
          <li
            v-for="(item, index) in allFeatures"
            :key="index"
            class="li"
            @mouseout="selectClear()"
            @mouseover="selectFeature(item.properties)"
            @click="getFeatureDetail(item.properties,lnglat)"
          >
            <span v-if="item.properties.node_type2==-1">{{index+1}}管线点</span>
            <span v-else-if="item.properties.node_type2==0">{{index+1}}节点</span>
            <span v-else-if="item.properties.node_type2==1">{{index+1}}供气站</span>
            <span v-else-if="item.properties.node_type2==2">{{index+1}}用户站</span>
            <span v-else-if="item.properties.node_type2==21">{{index+1}}阀门</span>
            <span v-else-if="item.properties.node_type2==22">{{index+1}}三通</span>
            <span v-else-if="item.properties.node_type2==23">{{index+1}}变径</span>
            <span v-else-if="item.properties.node_type2==24">{{index+1}}钢塑转换</span>
            <span v-else-if="item.properties.node_type2==25">{{index+1}}其他管件</span>
            <span v-else-if="item.properties.node_type2==9000">{{index+1}}监测点</span>
            <span v-else-if="item.properties.node_type2==9002">{{index+1}}泄漏报警</span>
            <span v-else-if="item.properties.node_type2==9003">{{index+1}}摄像头</span>
            <span v-else-if="item.properties.node_type2==9004">{{index+1}}管线</span>
          </li>
        </div>
      </div>
    </div> -->

    <dialog-node v-if="activeItem == 'node'" ref="dialogRef" />
    <dialog-monitor v-else-if="activeItem == 'monitoring'" ref="dialogRef" />
    <dialog-valve
      v-else-if="activeItem == 'valve' || activeItem == 'nodeToValve'"
      ref="dialogRef"
    />
    <dialog-userSta v-else-if="activeItem == 'userSta'" ref="dialogRef" />
    <dialog-station v-else-if="activeItem == 'station'" ref="dialogRef" />
    <dialog-line v-else-if="activeItem == 'line'" ref="dialogRef" />
    <dialog-warn v-else-if="activeItem == 'warn'" ref="dialogRef" />
    <dialog-camera v-else-if="activeItem == 'camera'" ref="dialogRef" />
    <dialog-fittings
      v-else-if="activeItem == 'fittings' || activeItem == 'pipePoints'"
      ref="dialogRef"
    />
    <monitoring-list v-else-if="activeItem == 'monitoringList'" ref="monitoringList" />
    <equipment-desc ref="EquipmentDesc"></equipment-desc>
    
    <station-desc ref="StationDesc" :postion="postion"></station-desc>
  </div>
</template>
<script>
import dialogNode from '../../dialog/node'
import dialogMonitor from '../../dialog/monitoring'
import dialogValve from '../../dialog/valve'
import dialogUserSta from '../../dialog/userSta'
import dialogStation from '../../dialog/station'
import dialogLine from '../../dialog/line'
import dialogWarn from '../../dialog/warn'
import dialogCamera from '../../dialog/camera'
import dialogFittings from '../../dialog/fittings'
import monitoringList from '../../dialog/monitoringList'
import { queryFeatrue, regionList } from '../../apis/geo'
import { getFindById, getCameraFindById } from '../../apis/equipment'
import {
  pipeFindById,
  pointFindById,
  stationFindByIds,
  monitorFindById,
  valveFindByPointId,
  pipeLink,getWorkHiddenById,getDetailById
} from '../../apis/tool'
import { constantDict, serverMap, commonLayer } from '../../utils/dict.js'
import { centerPointGetFourPoint, filterLayer, formatLocation } from '../../utils/geoTool.js'
import SetBase from '../../apis/setBase'
import StationDesc from '../../../Gis/dialog/stationDescBi.vue'
import { getAnalysisCloseValve, getAnalysisPipeBurst, getPatrolEventsDetail, getWorkFacilityAcceptDetail } from '../../../Gis/apis/commonType'
import EquipmentDesc from '../../../Gis/dialog/equipmentDesc.vue'
export default {
  name: 'EditFeature',
  components: {
    dialogNode,
    dialogMonitor,
    dialogValve,
    dialogUserSta,
    dialogStation,
    dialogLine,
    dialogWarn,
    dialogCamera,
    dialogFittings,
    monitoringList,
    StationDesc,
    EquipmentDesc
  },
  data() {
    return {
      activeItem: '',
      allFeatures: [],
      lanlat: [],
      cmpList: [],
      manageCmpList: [],
      postion:''
    }
  },
  mounted() {
  },
  methods: {
    /* selectClear(){
      this.$parent.lineHighlightRemove()
      this.$parent.pointHighlightRemove()
    },
    selectFeature(properties){
      if(properties.node_type2==constantDict.guanxian.dictCode){
        let path = formatLocation(properties.location_str, 'LineString')
        this.$parent.lineHighlight(path)
      }else{
        let lnglat = formatLocation(properties.location_str, 'Point')
        this.$parent.pointHighlight(lnglat)
      }
    },
    closeWindow() {
      if (this.infoWindow) {
        this.infoWindow.close()
      }
    }, */
    /*
     ****************************************************************************************************
     * 功能描述： 查询管线、节点，显示编辑窗口
     * 开发人： 宋德福
     * 开发时间： 2022-5-25
     ****************************************************************************************************
     */
    editFeature(lnglat) {
      let points = centerPointGetFourPoint(this.$parent.map, lnglat)
      let a = points.leftBottomPoint[0]
      let b = points.leftBottomPoint[1]
      let c = points.upRightPoint[0]
      let d = points.upRightPoint[1]
      let STYLES = ''
      let line = filterLayer(this.$store)
      let lineP = {}
      if (line) {
        lineP = { CQL_FILTER: line }
      }
      let parames = {
        SERVICE: 'WMS',
        VERSION: '1.1.0',
        REQUEST: 'GetFeatureInfo',
        FORMAT: 'image/png',
        TRANSPARENT: true,
        QUERY_LAYERS: commonLayer['groupLayer'],
        LAYERS: commonLayer['groupLayer'],
        exceptions: 'application,Fvnd.ogc.se_inimage',
        INFO_FORMAT: 'application/json',
        FEATURE_COUNT: 50,
        X: 50,
        y: 50,
        SRS: 'EPSG:4326',
        WIDTH: 101,
        HEIGHT: 101,
        STYLES,
        BBOX: a + ',' + b + ',' + c + ',' + d,
        ...lineP
      }
      queryFeatrue(parames).then(res => {
        let features = res.features
        if (features.length == 1) {
           let properties = features[0].properties;
          if(this.$parent.$refs.LeftTop&&this.$parent.$refs.LeftTop.leftTopActive =='pipeAnalysis'&&properties.node_type2== constantDict.guanxian.dictCode){
              this.pipeAnalysis(properties,lnglat)
          }else if(this.$parent.$refs.LeftTop&&this.$parent.$refs.LeftTop.leftTopActive =='valueAnalysis'&&properties.node_type2== serverMap['1002'][0].dictCode){
              this.valueAnalysis(properties,lnglat)
          }else{
            if(this.$parent.$refs.LeftTop&&(this.$parent.$refs.LeftTop.leftTopActive =='pipeAnalysis'||this.$parent.$refs.LeftTop.leftTopActive =='valueAnalysis')){
              let msg = this.$parent.$refs.LeftTop.leftTopActive =='pipeAnalysis'?'请选择管线':'请选择阀门'
              this.$message.warning(msg)
              return
            }else{
          console.log(1)
              this.getFeatureDetail(features[0].properties, lnglat)
            }
          }
        } else if (features.length > 1) {
          //点击范围内多个元素，优先选择点
         let properties = {}
          if(this.$parent.$refs.LeftTop&&this.$parent.$refs.LeftTop.leftTopActive =='pipeAnalysis'){
            properties = this.getPointAndLine(features,'line')
          }else if(this.$parent.$refs.LeftTop&&this.$parent.$refs.LeftTop.leftTopActive =='valueAnalysis'){
            properties = this.getPointAndLine(features)
          }else{
              properties = this.getPointAndLine(features)
          }
           if(this.$parent.$refs.LeftTop&&this.$parent.$refs.LeftTop.leftTopActive =='pipeAnalysis'&&properties.node_type2== constantDict.guanxian.dictCode){
              this.pipeAnalysis(properties,lnglat)
          }else if(this.$parent.$refs.LeftTop&&this.$parent.$refs.LeftTop.leftTopActive =='valueAnalysis'&&properties.node_type2== serverMap['1002'][0].dictCode){
              this.valueAnalysis(properties,lnglat)
          }else{
            if(this.$parent.$refs.LeftTop&&(this.$parent.$refs.LeftTop.leftTopActive =='pipeAnalysis'||this.$parent.$refs.LeftTop.leftTopActive =='valueAnalysis')){
              let msg = this.$parent.$refs.LeftTop.leftTopActive =='pipeAnalysis'?'请选择管线':'请选择阀门'
              this.$message.warning(msg)
              return
            }else{
              this.getFeatureDetail(properties, lnglat)
            }
          }
          
        }
      })
    },
    clear(){
      this.$refs.StationDesc.close()
    },
    getFeatureDetail(properties, lnglat) {
      let that = this
       that.$parent.$refs.markInfo.close()
      let node_type2 = properties.node_type2
      if (node_type2 == constantDict.guanxian.dictCode) {
        
          pipeFindById({ id: properties.id }).then(res => {
          let obj = {
            geometryType: null,
            equipmentType: 'line',
            title: '管线',
            point: res.data.line.split('(')[1].split(')')[0],
            ...res.data
          }
          console.log(obj)
          let path = formatLocation(properties.location_str, 'LineString')
          that.$parent.lineHighlight(path)
          that.$parent.$refs.markInfo.openEquipmentInfo(obj, lnglat)
        })
        
      } else if (node_type2 == constantDict.jiedian.dictCode) {
        let pointStr = properties.location_str.split('(')[1].split(')')[0]
        if (
          serverMap[1012][0].dictCode == properties.channel_type ||
          serverMap[1012][1].dictCode == properties.channel_type
        ) {
          pointFindById({ id: properties.id }).then(res => {
            let obj = {
              geometryType: null,
              equipmentType: 'stationPoint',
              title: '进出站',
              ...res.data
            }
            obj.point = pointStr
            let point = formatLocation(properties.location_str, 'Point')
            that.$parent.pointHighlight(point)
            that.$parent.$refs.markInfo.openEquipmentInfo(obj, lnglat)
          })
        } else {
          pointFindById({ id: properties.id }).then(res => {
            let obj = {
              geometryType: null,
              equipmentType: 'node',
              title: '节点',
              ...res.data
            }
            obj.point = pointStr
            pipeLink({ pointIds: properties.id }).then(res => {
              if (res.data.length > 0 && res.data[0].pipes.length == 2) {
                obj.pipeLink = res.data[0].pipes
              } else {
                obj.pipeLink = []
              }
              if (res.data.length > 0 && res.data[0].pipes.length > 0) {
                obj.pressureLevel = res.data[0].pipes[0].pressureLevel
              }
              let point = formatLocation(properties.location_str, 'Point')
              that.$parent.pointHighlight(point)
              that.$parent.$refs.markInfo.openEquipmentInfo(obj, lnglat)
            })
          })
        }
      } else if (node_type2 == constantDict.jiancedian.dictCode) {
        // 监测点
        let pointStr = properties.location_str.split('(')[1].split(')')[0]
        monitorFindById({ id: properties.id }).then(res => {
          let obj = {
            geometryType: null,
            equipmentType: 'monitoring',
            title: '监测点',
            ...res.data
          }
          obj.point = pointStr
          let point = formatLocation(properties.location_str, 'Point')
          that.$parent.pointHighlight(point)
          that.$parent.$refs.markInfo.openEquipmentInfo(obj, lnglat)
        })
      } else if (node_type2 == serverMap['1002'][0].dictCode) {
        
          // 阀门
          let pointStr = properties.location_str.split('(')[1].split(')')[0]
          valveFindByPointId({ pointId: properties.id }).then(res => {
            let obj = {
              geometryType: null,
              equipmentType: 'valve',
              title: '阀门',
              ...res.data
            }
            obj.point = pointStr
            let point = formatLocation(properties.location_str, 'Point')
            that.$parent.pointHighlight(point)
            that.$parent.$refs.markInfo.openEquipmentInfo(obj, lnglat)
          })
        
        
      } else if (node_type2 == constantDict.yonghuzhan.dictCode) {
        if(!this.$parent.gis){
          this.postion =  properties.location_str.split('(')[1].split(')')[0]
          this.$refs.StationDesc.openD(properties.id )
        }else{
        let pointStr = properties.location_str.split('(')[1].split(')')[0]
        stationFindByIds({ id: properties.id }).then(res => {
          let obj = {
            geometryType: null,
            equipmentType: 'userSta',
            title: '用户站',
            ...res.data
          }
          obj.point = pointStr
          that.$parent.$refs.stationInfo.openDialog(obj, lnglat)
          //that.$parent.$refs.markInfo.openEquipmentInfo(obj, lnglat)
        })
        }
      } else if (node_type2 == constantDict.gongqizhan.dictCode) {
        console.log(this.$parent.gis,"__________________________")
        if(!this.$parent.gis){
          this.postion =  properties.location_str.split('(')[1].split(')')[0]
          this.$refs.StationDesc.openD(properties.id )
        }else{
          let pointStr = properties.location_str.split('(')[1].split(')')[0]
          stationFindByIds({ id: properties.id }).then(res => {
            let obj = {
              geometryType: null,
              equipmentType: 'station',
              title: '供气站',
              ...res.data
            }
            obj.point = pointStr
            that.$parent.$refs.stationInfo.openDialog(obj, lnglat)
            //that.$parent.$refs.markInfo.openEquipmentInfo(obj, lnglat)
          })
        }
        
      } else if (node_type2 == constantDict.xieloubaojing.dictCode) {
        // 泄漏报警器
        getFindById({ id: properties.id }).then(res => {
          let obj = {
            geometryType: null,
            equipmentType: 'warn',
            title: '泄漏报警器',
            ...res.data
          }
          obj.point = lnglat.lng + ' ' + lnglat.lat //features[0].geometry.coordinates.join(' ')
          if (!obj.list) obj.list = []
          let point = formatLocation(properties.location_str, 'Point')
          that.$parent.pointHighlight(point)
          that.$parent.$refs.markInfo.openEquipmentInfo(obj, lnglat)
        })
      } else if (node_type2 == constantDict.shexiangtou.dictCode) {
        // 摄像头
        getCameraFindById({ id: properties.id }).then(res => {
          let obj = {
            geometryType: null,
            equipmentType: 'camera',
            title: '摄像头',
            ...res.data
          }
          obj.point = lnglat.lng + ' ' + lnglat.lat // features[0].geometry.coordinates.join(' ')
          if (!obj.list) obj.list = []
          let point = formatLocation(properties.location_str, 'Point')
          that.$parent.pointHighlight(point)
          that.$parent.$refs.markInfo.openEquipmentInfo(obj, lnglat)
        })
      } else if (
        node_type2 == constantDict.qitaguanjian.dictCode ||
        node_type2 == constantDict.gangsuzhuanhuan.dictCode ||
        node_type2 == constantDict.santong.dictCode ||
        node_type2 == constantDict.bianjin.dictCode
      ) {
        // 其他管件
        let pointStr = properties.location_str.split('(')[1].split(')')[0]
        pointFindById({ id: properties.id }).then(res => {
          let obj = {
            geometryType: null,
            equipmentType: 'fittings',
            title: '管件',
            addOrEdit: 'edit',
            ...res.data
          }
          obj.point = pointStr
          let point = formatLocation(properties.location_str, 'Point')
          that.$parent.pointHighlight(point)
          that.$parent.$refs.markInfo.openEquipmentInfo(obj, lnglat)
        })
      } else if (node_type2 == constantDict.guanxiandian.dictCode) {
        // 管线点
        let pointStr = properties.location_str.split('(')[1].split(')')[0]
        pointFindById({ id: properties.id }).then(res => {
          let obj = {
            geometryType: null,
            equipmentType: 'pipePoints',
            title: '管线点',
            addOrEdit: 'edit',
            ...res.data
          }
          obj.point = pointStr
          let point = formatLocation(properties.location_str, 'Point')
          that.$parent.pointHighlight(point)
          that.$parent.$refs.markInfo.openEquipmentInfo(obj, lnglat)
        })
      } else if (node_type2 == constantDict.yinbigongc.dictCode) {
        // 隐蔽工程
        let pointStr = properties.location_str.split('(')[1].split(')')[0]
        getWorkHiddenById({ id: properties.id }).then(res => {
          let obj = {
            geometryType: null,
            equipmentType: 'hidden',
            title: '隐蔽工程',
            addOrEdit: 'edit',
            ...res.data
          }
          obj.point = pointStr
          let point = formatLocation(properties.location_str, 'Point')
          that.$parent.pointHighlight(point)
          that.$parent.$refs.markInfo.openEquipmentInfo(obj, lnglat)
        })
      }else if (node_type2 == constantDict.jiancedianNew.dictCode) {
        // 检测点
        let pointStr = properties.location_str.split('(')[1].split(')')[0]
        getDetailById({ id: properties.id }).then(res => {
          let obj = {
            geometryType: null,
            equipmentType: 'markerCheck',
            title: '检测点',
            addOrEdit: 'edit',
            ...res.data
          }
          obj.point = pointStr
          let point = formatLocation(properties.location_str, 'Point')
          that.$parent.pointHighlight(point)
          that.$parent.$refs.markInfo.openEquipmentInfo(obj, lnglat)
        })
      }else if (node_type2 == constantDict.sanfang.dictCode) {
        // 三方施工
        let pointStr = properties.location_str.split('(')[1].split(')')[0]
        getPatrolEventsDetail({ id: properties.id }).then(res => {
          let obj = {
            geometryType: null,
            equipmentType: 'events',
            title: '三方施工',
            addOrEdit: 'edit',
            ...res.data
          }
          obj.point = pointStr
          let point = formatLocation(properties.location_str, 'Point')
          that.$parent.pointHighlight(point)
          that.$parent.$refs.markInfo.openEquipmentInfo(obj, lnglat)
        })
      }else if (node_type2 == constantDict.zhongdian.dictCode) {
        // 重点设施
        let pointStr = properties.location_str.split('(')[1].split(')')[0]
        getWorkFacilityAcceptDetail({ id: properties.id }).then(res => {
          let obj = {
            geometryType: null,
            equipmentType: 'facilityAccept',
            title: '重点设施',
            addOrEdit: 'edit',
            ...res.data
          }
          obj.point = pointStr
          let point = formatLocation(properties.location_str, 'Point')
          that.$parent.pointHighlight(point)
          that.$parent.$refs.markInfo.openEquipmentInfo(obj, lnglat)
        })
      }
    },
    pipeAnalysis(properties, lnglat){
      console.log(lnglat)
      let that = this
       let path = formatLocation(properties.location_str, 'LineString')
       let pointObj = AMap.GeometryUtil.closestOnLine(lnglat, path)
      let icon = 'icon-a-ziyuan1'
       that.$parent.pointHighlight(pointObj,icon)
      getAnalysisCloseValve({lineId:properties.id,pointId:0}).then(res=>{
        if(res.data.length==0){
          this.$parent.removeHighligAll()
          that.$message.warning('暂无数据')
        }else{
          that.$parent.pointHighlightAll(res.data)
        }
        
      })
      
    },
    valueAnalysis(properties, lnglat){
      this.$parent.pointHighlight(lnglat)
       getAnalysisPipeBurst({id:properties.id}).then(res=>{
         if(res.data.length==0){
          this.$parent.removeHighligAll()
          this.$message.warning('暂无数据')
        }else{
          this.$parent.pointHighlightAll(res.data,1)
        }
      })
      
    },
    openEQ(id,type){
      this.$refs.EquipmentDesc.openD(id,type)
    },
    openUserStion(id,postion){
      this.postion = postion;
          this.$refs.StationDesc.openD(id,'point')
    },
    
    changeLayer(lnglat) {
      let that = this
      let points = centerPointGetFourPoint(this.$parent.map, lnglat)
      let a = points.leftBottomPoint[0]
      let b = points.leftBottomPoint[1]
      let c = points.upRightPoint[0]
      let d = points.upRightPoint[1]
      let STYLES = ''
      let line = filterLayer(this.$store)
      let lineP = {}
      if (line) {
        lineP = { CQL_FILTER: line }
      }
      let parames = {
        SERVICE: 'WMS',
        VERSION: '1.1.0',
        REQUEST: 'GetFeatureInfo',
        FORMAT: 'image/png',
        TRANSPARENT: true,
        QUERY_LAYERS: commonLayer['groupLayer'],
        LAYERS: commonLayer['groupLayer'],
        exceptions: 'application,Fvnd.ogc.se_inimage',
        INFO_FORMAT: 'application/json',
        FEATURE_COUNT: 50,
        X: 50,
        y: 50,
        SRS: 'EPSG:4326',
        WIDTH: 101,
        HEIGHT: 101,
        STYLES,
        BBOX: a + ',' + b + ',' + c + ',' + d,
        ...lineP
      }
      queryFeatrue(parames).then(res => {
        let features = res.features

        if (features.length > 0) {
          let properties = that.getPointAndLine(features)

          let path = []
          let point = []
          let featureType = ''
          switch (properties.node_type2 + '') {
            case constantDict.sanfang.dictCode: {
              featureType = 'eventsIds'
              point = formatLocation(properties.location_str, 'Point')
              break
            }
            case constantDict.yinbigongc.dictCode: {
              featureType = 'hidden'
              point = formatLocation(properties.location_str, 'Point')
              break
            }
            case constantDict.zhongdian.dictCode: {
              featureType = 'facilityAcceptIds'
              point = formatLocation(properties.location_str, 'Point')
              break
            }
            case constantDict.jiancedianNew.dictCode: {
              featureType = 'check'
              point = formatLocation(properties.location_str, 'Point')
              break
            }

            case constantDict.guanxian.dictCode: {
              featureType = 'line'
              path = formatLocation(properties.location_str, 'LineString')
              break
            }
            case constantDict.guanxian.dictCode: {
              featureType = 'line'
              path = formatLocation(properties.location_str, 'LineString')
              break
            }
            case constantDict.guanxian.dictCode: {
              featureType = 'line'
              path = formatLocation(properties.location_str, 'LineString')
              break
            }
            
            case constantDict.guanxian.dictCode: {
              featureType = 'line'
              path = formatLocation(properties.location_str, 'LineString')
              break
            }
            case constantDict.jiancedian.dictCode: {
              featureType = 'monitor'
              point = formatLocation(properties.location_str, 'Point')
              break
            }
            case constantDict.xieloubaojing.dictCode: {
              featureType = 'warn'
              point = formatLocation(properties.location_str, 'Point')
              break
            }
            case constantDict.shexiangtou.dictCode: {
              featureType = 'camera'
              point = formatLocation(properties.location_str, 'Point')
              break
            }
            case constantDict.yonghuzhan.dictCode: {
              featureType = 'station'
              point = formatLocation(properties.location_str, 'Point')
              break
            }
            case constantDict.gongqizhan.dictCode: {
              featureType = 'station'
              point = formatLocation(properties.location_str, 'Point')
              break
            }
            default:
              featureType = 'node'
              point = formatLocation(properties.location_str, 'Point')
          }
          let treeData = JSON.parse(JSON.stringify(that.$parent.$refs.layerManage.treeData))
          let nodeName = that.getNodeNamebyId(properties.layer_id, '', treeData)
          that.setTreeDataDisabled(treeData)
          let obj = {
            geometryType: null,
            equipmentType: 'changeLayer',
            title: '更换图层',
            featureType: featureType,
            nodeName: nodeName,
            options: treeData,
            ...properties
          }
          if(properties.node_type!='29'&&properties.node_type!='27'){
              if (featureType == 'line') {
                //高亮显示线
                that.$parent.lineHighlight(path)
              } else {
                //高亮显示点
                that.$parent.pointHighlight(point)
              }
              that.$parent.$refs.markInfo.openEquipmentInfo(obj, lnglat)
          }
        }
      })
    },
    getNodeNamebyId(id, nodeName, treeData) {
      for (let i = 0; i < treeData.length; i++) {
        if (id == treeData[i].id) {
          let name = treeData[i].name
          // 图层树删除当前选中图层
          treeData.splice(i, 1)
          return name
        }
        if (treeData[i].children) {
          nodeName = this.getNodeNamebyId(id, nodeName, treeData[i].children)
          if (nodeName != '') {
            return nodeName
          }
        }
      }
      return nodeName
    },
    setTreeDataDisabled(treeData) {
      for (let i = 0; i < treeData.length; i++) {
        if (treeData[i].layerType == 1) {
          // 图层组没有子级禁用选中
          if (treeData[i].children && treeData[i].children.length > 0&&treeData[i].isDelete!=0) {
            this.setTreeDataDisabled(treeData[i].children)
          } else {
            delete treeData[i]
          }
        }
        // else{
        //   if(this.$store.getters.currentLayer==treeData[i].id){
        //     delete treeData[i]
        //   }
        // }
      }
    },
    getPointAndLine(features,type) {
      let pointFeatures = []
      let lineFeatures = []
      for (let i = features.length - 1; i >= 0; i--) {
        // 这里倒着循环为了点击查看的时候在上面的优先弹出
        let item = features[i]
       
        if (item.properties.node_type2 == constantDict.guanxian.dictCode) {
          lineFeatures.push(item)
        } else {
          pointFeatures.push(item)
        }
        
      }
      if(type=='line'){
        if (lineFeatures.length > 0) {
          return lineFeatures[0].properties
        } else {
          return pointFeatures[0].properties
        }
      }else{
        if (pointFeatures.length > 0) {
          return pointFeatures[0].properties
        } else {
          return lineFeatures[0].properties
        }
      }
      
    },
    
    editEquipment(item, type) {
      let that = this
      this.activeItem = type
      this.$nextTick(function() {
        let addOrEdit = 'edit'
        if (type == 'nodeToValve') {
          // 节点转换成阀门，相当于吸附状态下新增阀门
          addOrEdit = type
        }
        that.$refs.dialogRef.openDialog({
          addOrEdit: addOrEdit,
          diaCode: that.activeItem,
          ...item
        })
      })
    },
    openEquipmentList(item) {
      this.activeItem = 'monitoringList'
      this.$nextTick(function() {
        this.$refs.monitoringList.openEquipmentList(item)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  text-align: center;
  button {
    margin: 7px 15px 0 15px;
  }
}
.Bubble {
  div {
    font-size: 14px;
  }
  position: relative;
  .lj {
    display: block;
    position: absolute;
    top: 0.5rem;
    left: -21px;
    transform: rotate(-90deg);
    z-index: 1;
    .tip {
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid #222;
      position: relative;
    }
    .tip:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      bottom: -10px;
      left: -12px;
      z-index: -1;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid rgba(0, 204, 255, 0.5);
    }
    // border-bottom: 12px solid rgba(8, 31, 48, 1);
  }
}
.markbox {
  background: #15202f;
  border: 1px solid #6eb7e3;
  opacity: 0.8;
  min-width: 290px;
  min-height: 115px;

  // background-size: 100% 100%;
  font-size: 18px;
  // margin-top: -0.6rem;
  margin-left: 0.1rem;
  overflow: visible;
  position: relative;
}
.markTitle {
  height: 42px;
  display: flex;
  // line-height: 1;
  justify-content: space-between;
  align-items: center;
  //background-image: linear-gradient(to right, #0a608c, #081f30);
  background-color: #285f88;
  opacity: 1;
  p {
    display: flex;
    line-height: 1;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    img {
      margin-right: 10px;
      height: 24px;
      width: auto;
    }
  }
  i {
    font-size: 18px;
    color: #679db4;
    cursor: pointer;
    margin-right: 10px;
  }
  i:hover {
    color: #548aa1;
  }
}
.mark-con {
  margin: 5px 10px;
  line-height: 2;
  .li:hover,
  .li.active {
    color: var(--font-color-b1);
    cursor: pointer;
  }
}
</style>
