<template>
  <div class="gis-main gis-default">
    <div style="display: flex">
      <div class="layer-control">
        <layer-manage v-show="false" ref="layerManage" class="layer" />
      </div>
      <div class="view">
        <!-- <div class="top-tool" style="width:100%" v-show="flase">
          <LeftTop ref="LeftTop"></LeftTop>
          <searchTool ref="searchTool" />
        </div> -->
        <div ref="amap" class="map-size">
          <div id="amapStation">
            <layer-wms ref="layerWms" :layer-name="layerName" />
            <zoom ref="zoom" v-show="false" />
          </div>
        </div>
        <toolbar v-show="false" ref="Toolbar" :props-position="propsPosition" :bottom-position="bottomPosition" />
        <toolInfo ref="toolInfo" />
        <mark-info ref="markInfo"></mark-info>
        <editVector ref="editVector"></editVector>
        <edit-feature ref="editFeature"></edit-feature>
        <rightTool ref="rightTool" :legend-name="'overView'" :query="false" :layer-tree="true" :home-query="true"
          @closeDraw="closeDraw" @draw="draw" @settingsItem="settingsItem" :legendPosition="'0.2'" />
        <!-- <bottom :display-list="displayList" :pipe-display-list="pipeDisplayList" /> -->
      </div>
      <station-desc ref="StationDesc"></station-desc>
    </div>
    <!-- <dialog-display
      ref="dialogDisplay"
      :display-settings="displaySettings"
      @confirm="onConfirm"
    ></dialog-display>
    <query-info ref="queryInfo"></query-info>
    <station-info ref="stationInfo"></station-info>
    <station-desc ref="StationDesc"></station-desc> -->
    <!-- <div
      v-if="isEdit && drawTipmouse && drawType != 'rule'"
      ref="drawTipmouse"
      class="drawTipmouse"
    >
      {{ drawTipmouse }}
    </div>
    <div v-if="isEdit && drawType == 'rule'" ref="drawTipmouse" class="drawTipmouse">
      <span v-if="ruleM != 0">
        总长：<span style="color:red">{{ ruleM }}</span
        >米<br />{{ drawTipmouse }}
      </span>
      <span v-else>{{ tipMessege }}</span>
    </div> -->
  </div>
</template>

<script>
import 'echarts-extension-amap'
import '@/assets/gis/iconFont/iconfont.css'
import '@/assets/gis/iconFont/iconfont.js'
//import { loadMixin } from './load/amapLoad' //有地图的总入口都要混入
import { intMixin } from './load/amapInit'
import editVector from './tool/layer/editVector'
import zoom from './tool/question/zoom' //层级控制器
import layerWms from './tool/layer/layerWms' //瓦片
import bottom from './tool/layer/bottom'
import Toolbar from './tool/leftTool/Toolbar'
import rightTool from './tool/rightTool/index'
import searchTool from './tool/searchTool/index'
import toolInfo from './tool/layer/toolInfo'
import markInfo from './tool/layer/markInfoBi'
import editFeature from './tool/layer/editFeatureBi'
import dialogDisplay from './dialog/display'
import layerManage from './layerManage/layerGis'
import topTool from './tool/topTool/index'
import SetBase from './apis/setBase'
import queryInfo from './tool/layer/queryInfo'
import stationInfo from './tool/layer/stationInfo'
import { commonLayer, initFilter, constantDict, serverMap } from './utils/dict.js'
import { formatLocation } from './utils/geoTool.js'
import pl from '@/assets/gis/pl.png'
import './style/skin.scss'
import LeftTop from './tool/leftTool/leftTop.vue'
import StationDesc from './dialog/stationDescBi.vue'
import { selectScadaLineWarn, selectScadaStationWarn, getlistCarInfo, getCarInfo, getbiUserLists, getBiUserInfo } from './apis/base'
import scadaApi from "@/apis/scadaApi";
var echartMap = null
// import { log } from 'console'
export default {
  name: 'Index',
  components: {
    zoom,
    layerWms,
    bottom,
    Toolbar,
    rightTool,
    toolInfo,
    dialogDisplay,
    layerManage,
    topTool,
    markInfo,
    editFeature,
    queryInfo,
    stationInfo,
    editVector,
    searchTool,
    LeftTop,
    StationDesc
  },
  mixins: [intMixin], //有地图的总入口都要混入
  props: {
   // mapStyle: { type: String, default: 'darkblue' },
    warnList: { type: Array, default: () => [] },
  },
  data() {
    return {
      drawTipmouse: '',
      layerName: commonLayer['pressure'],
      propsPosition: 0.1, //组件边距
      bottomPosition: 40,
      searchPosition: 0.1,
      displaySettings: null,
      displayList: [],
      isEdit: false,
      isSelectNode: false,
      layerShow: true,
      pipeDisplayList: [],
      editTool: null,
      equipmentTool: null,
      dragState: true,
      loadStateSet: 0,
      toolIndex: 0,
      queryVector: true, //根据层级判断是否开启 默认关闭
      queryVectorGeo: [], //要吸附的数据
      queryVectorObj: null, //点位对象
      queryFeatrue: null, //吸附好的值
      queryLayer: '', //要查询的图层
      queryType: '',
      ruleObj: [],
      ruleM: '0',
      gis: false,
      LabelsLayerHighlightAll: [],
      timer: '',
      isClick: true,
      polyline: null,
      markerZhong: null,
      markerqi: null,
      companyId: '',
      xlbjMarkers: [],
      infoWindowHF: null,
      carlist: [],
      overlayGroups: null,
      userlist: [],
      marker2: [],
      equipmentmarker: [],
      linejson: require('../bi/line.json'),
      oldOption:[],
      newOption:[],
      simulationState:false
    }
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  computed: {},
  watch: {
    zoom(val){
       console.log('----',val)
       this.setSimulatOption()
    },
    loadStateSet(val) {
      if (this.loadStateSet >= 1) {
        this.setlayerInit()
      }
    },
    queryType(val) {
      this.setDrawPoint(val)
    },
    warnList(val) {
      this.setXlbjMarker(val)
    },
  },

  mounted() {
    //  this.layerName = commonLayer['pressure']
    window.warnHandle = (e) => {
      this.warnHandle(e)
    }
    this.setStorInit()
    this.findSetting()
    this.replaceChart()
    this.$store.commit('gis/notGroupLayerChange', [])
    this.$store.commit('gis/groupLayerChange', [])
    this.$eventBus.$emit('setFilter')
    this.$eventBus.$emit('setMarker')
    // 操作图例
    this.$eventBus.$on("vertex", (data) => {
      this.vertexOperate(data)
    })
  },
  methods: {
    closeSimulation(){
     
      this.simulationState=false
      let options = window.echartMap.getOption()
        options.series[0].data = []
        window.echartMap.setOption(options)
        window.echartMap.resize()

        let doc = document.querySelector('.amap-ec-layer')
        doc.style.display = 'none'
    },
    openSimulation(){
       this.simulationState=true
        let doc = document.querySelector('.amap-ec-layer')
        doc.style.display = 'block'
       this.setSimulatOption()
    },
    setSimulatOption() {
     if(!this.simulationState){
      return
     }
      let series
      if (this.map.getZoom() < 16) {
          series = this.oldOption
      } else {
          series = this.oldOption.concat(this.newOption)
      }
       let options = window.echartMap.getOption()
    
      
        options.series[0].data = series
        window.echartMap.setOption(options)
        window.echartMap.resize()
        
    
      
    },
    simulationFun(){
      let data=this.linejson
      let line=[]
      let lineNew=[]
      let standard = 500
      data.forEach(item => {
            let arrLine = []
            let { direction, line: pathline } = item
            if (direction == 0) return
            let lineAllLength = AMap.GeometryUtil.distance(
              pathline[0],
              pathline[pathline.length - 1]
            )
            if (lineAllLength < 100) {
              if (direction == -1) {
                while (pathline.length) {
                  arrLine.push(pathline.pop())
                }
              } else {
                arrLine = pathline
              }
              let nL = {
                coords: arrLine,
                lineStyle: {
                  color: 'rgba(255,243,5,1)'
                },
                id: item.pipeId
              }
              lineNew.push(nL)
            } else {
              if (direction == -1) {
                let distance = 0
                let j = pathline.length - 1
                let lengths = pathline.length - 1

                while (j >= 0) {
                  if (j == lengths) {
                    arrLine.push(pathline[j])
                  } else {
                    let n = j + 1
                    let pStart = pathline[n]

                    let pEnd = pathline[j]
                    if (arrLine.length == 0) {
                      arrLine.push(pStart)
                    }
                    distance = distance + AMap.GeometryUtil.distance(pStart, pEnd)
                  }
                  if (standard <= distance) {
                    arrLine.push(pathline[j])
                    distance = 0
                    let nL = {
                      coords: arrLine,
                      lineStyle: {
                        color: 'rgba(255,243,5,1)'
                      },
                      id: item.pipeId
                    }
                    line.push(nL)
                    arrLine = []
                  } else {
                    arrLine.push(pathline[j])
                    if (j == 0 && arrLine.length > 0) {
                      let nL = {
                        coords: arrLine,
                        lineStyle: {
                          color: 'rgba(255,243,5,1)'
                        },
                        id: item.pipeId
                      }
                      line.push(nL)
                    }
                  }
                  j = j - 1
                }
              } else {
                let distance = 0
                let i = 0
                while (i < pathline.length) {
                  if (i == 0) {
                    arrLine.push(pathline[i])
                  } else {
                    let n = i - 1
                    let pStart = pathline[n]
                    let pEnd = pathline[i]
                    if (arrLine.length == 0) {
                      arrLine.push(pStart)
                    }
                    distance = distance + AMap.GeometryUtil.distance(pStart, pEnd)
                  }

                  if (standard <= distance) {
                    arrLine.push(pathline[i])
                    distance = 0
                    let nL = {
                      coords: arrLine,
                      lineStyle: {
                        color: 'rgba(255,243,5,1)'
                      },
                      id: item.pipeId
                    }
                    line.push(nL)
                    arrLine = []
                  } else {
                    arrLine.push(pathline[i])
                    if (i == pathline.length - 1 && arrLine.length > 0) {
                      let nL = {
                        coords: arrLine,
                        lineStyle: {
                          color: 'rgba(255,243,5,1)'
                        },
                        id: item.pipeId
                      }
                      line.push(nL)
                    }
                  }
                  i++
                }
              }
            }
          })

          this.oldOption = line
          this.newOption = lineNew
      let doc = document.querySelector('.amap-ec-layer')
        doc.style.display = 'none'
        
    },
    // 更换图列
    replaceChart() {
      let data = [
        {
          name: '供气站',
          img: 'icon-menzhan',
          size: '12px',
          color: '#c83365',
          id: constantDict.gongqizhan.dictCode,
          check: true
        },
        {
          name: '调压设施',
          img: 'icon-tiaoyazhan',
          type: 'pic',
          pic: require('@/assets/gis/map/right1.png'),
          pic2: require('@/assets/gis/map/right2.png'),
          id: constantDict.yonghuzhan.dictCode,
          check: true
        },
        {
          name: '阀井',
          img: 'icon-famen1',
          color: '#ff0000',
          size: '10px',
          id: serverMap['1002'][0].dictCode,
          check: true
        },
        {
          name: '巡线车辆',
          img: 'icon-tiaoyazhan',
          type: 'pic',
          pic: require('@/assets/car2.png'),
          pic2: require('@/assets/car2.png'),
          id: 'car',
          check: true
        },
        {
          name: '巡线人员',
          img: 'icon-tiaoyazhan',
          type: 'pic',
          pic: require('@/assets/user2.png'),
          pic2: require('@/assets/user2.png'),
          id: 'user',
          check: true,
          bottom: true
        },
        {
          name: '泄漏报警',
          img: 'icon-tiaoyazhan',
          type: 'pic',
          pic: require('@/assets/gis/map/9002.png'),
          pic2: require('@/assets/gis/map/9002.png'),
          id: constantDict.xieloubaojing.dictCode,
          check: true
        },
        // {
        //   name: '重点设施',
        //   img: 'icon-ranqisheshi',
        //   color: '#00a29a',
        //   id: constantDict.zhongdian.dictCode,
        //   check: false
        // },
        //  {
        //   name: '隐蔽工程',
        //   img: 'icon-a-23gongcheng',
        //   color: '#d81e06',
        //   id: constantDict.yinbigongc.dictCode,
        //   check: false
        // },
        //   {
        //   name: '三方施工',
        //   img: 'icon-shigong2',
        //   color: '#ff6000',
        //   id: constantDict.sanfang.dictCode,
        //   check: false
        // },
      ]
      this.$eventBus.$emit('replaceGraph', data)
    },
    clearQuery() {
      if (this.queryVectorObj) {
        this.map.remove(this.queryVectorObj)
        this.queryVectorObj = null
      }
    },
    /**
     * 轨迹
     */
    addPolyline(type, locationList) {
      let that = this
      if (this.polyline) {
        this.map.remove(this.polyline)
        this.polyline = null
      }
      if(this.markerqi){
        this.map.remove(this.markerqi)
        this.markerqi = null
      }
      if(this.markerZhong){
        this.map.remove(this.markerZhong)
        this.markerZhong = null
      }
      let val = {}
      if (type == 'user') {
        val = {
          path: [
            [
              locationList
              // [[125.7791553522532, 41.676378831054784], [125.77919827883537, 41.676202541579499], [125.78041095418557, 41.676475234499051], [125.78063631782761, 41.675834193167397], [125.78080802337507, 41.675433544876626], [125.78136606627525, 41.675633991021932], [125.78264312177788, 41.675810528528118]]
            ]
          ]
        }
      } else {
        val = {
          path: [
            [
              locationList
              // [[125.75586700373934, 41.676337791607708], [125.75666118174033, 41.676337970839874], [125.75754121639987, 41.676366216503894], [125.76150673061466, 41.676543402002807], [125.7641682836606, 41.676676213715929], [125.76636298375988, 41.676776862209763], [125.76979721561092, 41.676913829358099], [125.77518461296374, 41.677163364773449], [125.7758392516627, 41.677211577433326]]
            ]
          ]
        }
      }
      // this.map.setZoomAndCenter(18, val.path[0][0][0])
      this.map.setCenter(val.path[0][0][0])

      this.polyline = new AMap.OverlayGroup()
      if (val.path && val.path.length > 0) {
        for (let i = 0; i < val.path.length; i++) {
          // 起点
          let qidian = new AMap.Icon({
            size: new AMap.Size(58, 70), // 图标尺寸
            image: require("@/assets/start.png"), // Icon的图像地址
            imageSize: new AMap.Size(20, 30), // 根据所设置的大小拉伸或压缩图片
          });
          this.markerqi = new AMap.Marker({
            position: new AMap.LngLat(
              val.path[i][0][0][0],
              val.path[i][0][0][1]
            ),
            offset: new AMap.Pixel(-35, -35),
            icon: qidian, // 添加 Icon 实例
            title: "自定义图标",
            zoom: 13,
          });
          that.map.add(this.markerqi);
          let arr = []
          for (let j = 0; j < val.path[i].length; j++) {
            if (j == val.path[i].length - 1) {
              // 终点
              let lastLineLength = val.path[i][j].length

              let zhongdian = new AMap.Icon({
                size: new AMap.Size(58, 70), // 图标尺寸
                image: require("@/assets/end.png"), // Icon的图像地址
                imageSize: new AMap.Size(20, 30), // 根据所设置的大小拉伸或压缩图片
              });
              this.markerZhong = new AMap.Marker({
                position: new AMap.LngLat(
                  val.path[i][j][lastLineLength - 1][0],
                  val.path[i][j][lastLineLength - 1][1]
                ),
                offset: new AMap.Pixel(-35, -35),
                icon: zhongdian, // 添加 Icon 实例
                title: "自定义图标",
                zoom: 13,
              });
              that.map.add(this.markerZhong);
            }

            let polyline = new AMap.Polyline({
              path: val.path[i][j],
              isOutline: true,
              outlineColor: "#ffeeff",
              borderWeight: 1,
              strokeColor: "#00FFA8",
              strokeOpacity: 1,
              strokeWeight: 6,
              // 折线样式还支持 'dashed'
              strokeStyle: "solid",
              // strokeStyle是dashed时有效
              strokeDasharray: [10, 5],
              lineJoin: "round",
              lineCap: "round",
              zIndex: 2021,
              showDir: true,
            });
            arr.push(polyline)
            // that.map.add(polyline);
          }
          that.polyline.addOverlays(arr)
          that.map.add(this.polyline);
        }
      }

    },
    setDrawPoint(val) {
      if (val) {
        this.getBoundsFeat(this.queryLayer)
        let activeToolItem = this.$refs.topTool.activeToolItem
        let image = pl
        switch (activeToolItem) {
          case 'node': {
            image = require('./image/tool-node.png')
            break
          }
          case 'monitoring': {
            image = require('./image/tool-monitoring.png')
            break
          }
          case 'valve': {
            image = require('./image/tool-valve.png')
            break
          }
          case 'userSta': {
            image = require('./image/tool-userSta.png')
            break
          }
          case 'station': {
            image = require('./image/tool-station.png')
            break
          }
          case 'warn': {
            image = require('./image/tool-warn.png')
            break
          }
          case 'camera': {
            image = require('./image/tool-camera.png')
            break
          }
        }
        if (!this.queryVectorObj) {
          this.queryVectorObj = new AMap.Marker({
            icon: new AMap.Icon({ image: image, imageOffset: new AMap.Pixel(0, 0) }),
            anchor: 'center',
            position: [0, 0]
          })
          this.map.add(this.queryVectorObj)
        } else {
          this.queryVectorObj.setIcon(
            new AMap.Icon({ image: image, imageOffset: new AMap.Pixel(0, 0) })
          )
        }
      } else {
        if (this.queryVectorObj) {
          this.map.remove(this.queryVectorObj)
          this.queryVectorObj = null
        }
      }
    },
    mouseHover(e) {
      let disX = e.pixel.x
      let disY = e.pixel.y
      if (this.$refs.drawTipmouse) {
        this.$refs.drawTipmouse.style.top = disY + 20 + 'px'
        this.$refs.drawTipmouse.style.left = disX + 270 + 'px'
      }
    },
    setStorInit() {
      this.$store.commit('gis/legendSelectChange', initFilter)
    },
    setlayerInit() {
      this.$store.commit('gis/legendSelectChange', initFilter)
      this.$eventBus.$emit('setFilter')
      // this.$store.commit('gis/legendSelectChange', initFilter) //默认选中图层
      // let filter =
      //   'node_type2 in (' + initFilter.toString() + ',' + constantDict.guanxian.dictCode + ')'
      // this.$refs.layerWms.init(filter, this.layerName)
    },
    settingsItem(val) {
      this.$refs[val].open()
    },
    getwarnIng() {
      let list = [];
      selectScadaLineWarn({}).then(res1 => {
        if (res1.data && res1.data.length > 0) {
          let legendSelect = this.$store.getters.legendSelect

          if (!legendSelect.find(el => el == '9000')) {
            legendSelect.push(9000)
            this.$store.commit('gis/legendSelectChange', legendSelect)
            this.$eventBus.$emit('setFilter')
          }
        }
        selectScadaStationWarn({}).then(res2 => {
          res1.data.forEach(el => {
            list.push(formatLocation(el, 'Point'))
          })
          res2.data.forEach(el => {
            list.push(formatLocation(el, 'Point'))
          })
          this.pointHighlightAllGis(list)
        })
      })
    },
    pointHighlightAllGis(list) {
      let that = this;
      this.removeHighligAllGis()
      list.forEach(el => {
        let pos = new AMap.Marker({
          content:
            '<div class="red"> <div class="scatterPoint scatterPoint2"></div>' +
            '<div class="scatterPoint scatterPoint3" ></div></div>',
          offset: new AMap.Pixel(0, 0),
          position: el,
          extData: el
        })
        pos.on('click', function (e) {
          that.$refs.editFeature.editFeature(e.lnglat)
        })

        that.map.add(pos)
        that.LabelsLayerHighlightAll.push(pos)
      })
    },
    removeHighligAllGis() {
      if (this.LabelsLayerHighlightAll) {
        this.LabelsLayerHighlightAll.forEach(el => {
          this.map.remove(el)
        })
        this.LabelsLayerHighlightAll = []
      }
    },
    async findSetting() {
      try {
        let { data } = await SetBase.findSetting()
        this.onConfirm(data, 1, true)
        this.loadStateSet = this.loadStateSet + 1
      } catch (error) { }
    },
    async onConfirm(val, type, need) {
      this.$store.commit('gis/isLegendSelectChange', [])
      if (!commonLayer[val]) {
        val = '40000'
      }
      this.layerName = commonLayer[val]

      if (this.loadStateSet > 2 && !need) {
        this.$eventBus.$emit('setFilter')
      }
      if (this.displaySettings === val) return
      if (!type) {
        try {
          await SetBase.setting({ value: val })
        } catch (error) { }
      }
      this.displayList = []
      this.pipeDisplayList = []
      this.displaySettings = val
      if (val == 'texture') {
        try {
          let res = await SetBase.getList({ pidDictCode: 1000240001 })
          let { data } = await SetBase.getList({ pidDictCode: 1000240002 })
          let data1 = await SetBase.getList({ pidDictCode: 1000240003 })
          res.data.forEach(el => {
            el.select = true
          })
          data.forEach(el => {
            el.select = true
          })
          data1.data.forEach(el => {
            el.select = true
          })
          data.push({
            "id": 1,
            "pidDictCode": "",
            "dictName": "默认",
            "dictCode": "0",
            "showName": "默认",
            "colour": "#ff0084",
            "sort": 1,
            "select": true
          })
          this.pipeDisplayList = [res.data, data, data1.data]
        } catch (error) { }
      } else {
        try {
          let res = await SetBase.getList({ pidDictCode: val })
          let retData = res.data
          retData.forEach(element => {
            element.select = true
          })
          retData.push({
            "id": 1,
            "pidDictCode": "",
            "dictName": "默认",
            "dictCode": "0",
            "showName": "默认",
            "colour": "#ff0084",
            "sort": 1,
            "select": true
          })
          this.displayList = retData
        } catch (error) { }
      }
    },
    /**
     * 插点取消/添加
     */
    vertexOperate(legendImg){
      let that = this
      if (that.overlayGroups) {
        this.map.remove(that.overlayGroups)
      }

      if (that.marker2) {
        this.map.remove(that.marker2)
      }

      if (that.equipmentmarker) {
        this.map.remove(that.equipmentmarker)
      }
      if (this.polyline) {
        this.map.remove(this.polyline)
        this.polyline = null
      }
      if(this.markerqi){
        this.map.remove(this.markerqi)
        this.markerqi = null
      }
      if(this.markerZhong){
        this.map.remove(this.markerZhong)
        this.markerZhong = null
      }
      legendImg.forEach((item,index) => { 
        if (item.check) {
          if(item.id == 'car') {
            that.getcarlistdots()
            if (that.overlayGroups) {
            that.map.add(that.overlayGroups)
            }
          } else if(item.id == 'user') {
            that.getuserListdots()
            if (that.marker2) {
            that.map.add(that.marker2)
            }
          } else if(item.id == '9002'){
            that.Equipmentdot()
            if(that.equipmentmarker){
            that.map.add(that.equipmentmarker)
            }
          }
        }
      })
    },
    /**
     * 添加泄漏报警标点
     */
    setXlbjMarker(list) {
      if (this.map) {
        if (this.infoWindowHF) {
          this.infoWindowHF.close();
          this.infoWindowHF = null;
        }
        this.map.remove(this.xlbjMarkers);
        this.xlbjMarkers = []
        let imgUrl = require('./image/warn.png')
        var icon = new AMap.Icon({
          size: new AMap.Size(40, 40),    // 图标尺寸
          image: imgUrl,  // Icon的图像
          imageOffset: new AMap.Pixel(0, 0),
          imageSize: new AMap.Size(40, 40)   // 根据所设置的大小拉伸或压缩图片
        })
        list.forEach(el => {
          let marker = new AMap.Marker({
            map: this.map,
            position: el.position,
            icon: icon,
            offset: new AMap.Pixel(0, 0),
            extData: { id: el.id, data: el.data },
            title: el.title,
            zIndex:10001
          });
          marker.on('click', (e) => {
            console.log(e)
            this.$emit('markerClick', e.target.getExtData())
            if (this.infoWindowHF) {
              this.infoWindowHF.close();
              this.infoWindowHF = null;
            }
            let data = e.target.getExtData().data
            let id = e.target.getExtData().id;
            this.infoWindowHF = new AMap.InfoWindow({
              content: "<div class='tr_title' style='padding:20px;background: rgba(40, 4, 4, 0.96);border: 2px solid #BC1414;box-shadow: 0px 0px 6px #BC1414; border-radius: 4px;'>" +
                "<div><span>报警器编码：</span><span>" + data.equipmentCode + "</span></div>" +
                "<div><span>报警器名称：</span><span>" + data.equipmentName + "</span></div>" +
                "<div><span>报警值：</span><span>" + data.equipmentName + "</span></div>" +
                "<div style='text-align: center;margin-top:20px'><button onclick='warnHandle(" + id + ")' style='background: rgba(255,0,0,0.2);border: 0;width: 100%;' class='el-button el-button--default el-button--mini'>修改</button></div></div>"
              ,
              size: new AMap.Size(0, 0),
              autoMove: true,
              closeWhenClickMap: true,
              offset: new AMap.Pixel(3, -25)
            });
            let clouddata = e.lnglat;
            let _location = [clouddata.lng, clouddata.lat];
            this.infoWindowHF.open(this.map, _location);
          })
          this.xlbjMarkers.push(marker)
        })
      }
    },
    /**
     * 关闭信息窗体
     */
    warnHandle(e) {
      this.$emit('warnHandle', e)
      if (this.infoWindowHF) {
        this.infoWindowHF.close();
        this.infoWindowHF = null;
      }
    },
    /**
     * 定位并打开泄漏报警信息窗体
     */
    setZoom(path, index) {
      if (this.infoWindowHF) {
        this.infoWindowHF.close();
        this.infoWindowHF = null;
      }
      this.map.setZoomAndCenter(20, path);
      let dom = this.xlbjMarkers[index]
      let data = dom.getExtData().data
      let id = dom.getExtData().id;
      this.infoWindowHF = new AMap.InfoWindow({
        content: "<div class='tr_title' style='padding:20px;background: rgba(40, 4, 4, 0.96);border: 2px solid #BC1414;box-shadow: 0px 0px 6px #BC1414; border-radius: 4px;'>" +
          "<div><span>报警器编码：</span><span>" + data.equipmentCode + "</span></div>" +
          "<div><span>报警器名称：</span><span>" + data.equipmentName + "</span></div>" +
          "<div><span>报警值：</span><span>" + data.equipmentName + "</span></div>" +
          "<div style='text-align: center;margin-top:20px'><button onclick='warnHandle(" + id + ")' style='background: rgba(255,0,0,0.2);border: 0;width: 100%;' class='el-button el-button--default el-button--mini'>修改</button></div></div>"
        ,
        size: new AMap.Size(0, 0),
        autoMove: true,
        closeWhenClickMap: true,
        offset: new AMap.Pixel(18, -5)
      });
      let clouddata = dom._position;
      let _location = [clouddata[0], clouddata[1]];
      this.infoWindowHF.open(this.map, _location);
    },
    /**
     * 获取地址
     */
    async getAdrss(lng, lat, obj, e) {
      let that = this
      var geocoder = new AMap.Geocoder({
        city: "010", //城市设为北京，默认：“全国”
        radius: 1000 //范围，默认：500
      });
      let lnglat = [lng, lat]
      let address = ''
      await geocoder.getAddress(lnglat, function (status, result) {
        if (status === 'complete' && result.regeocode) {
          address = result.regeocode.formattedAddress;
        } else {
          log.error('根据经纬度查询地址失败')
        }
        obj.adrsss = address
        that.$refs.markInfo.openEquipmentInfo(obj, e)
      });
    },
    /**
     * 车轨迹
     */
    getTrajectory(id) {
      let that = this
      getCarInfo({ caraId: id }).then(res => {
        if (res.code == 200) {
          that.addPolyline('car', res.data.locationList)
        }
      })
    },
    /**
     * 人轨迹
     */
    getuserTrajectory(id) {
      let that = this
      getBiUserInfo({ userId: id }).then(res => {
        if (res.code == 200) {
          that.addPolyline('user', res.data.locationList)
        }
      })
    },
    /**
     * 添加人和车辆坐标
     */
    async setCarMarker() {
      let that = this
      if (that.overlayGroups) {
        this.map.remove(that.overlayGroups)
      }

      if (that.marker2) {
        this.map.remove(that.marker2)
      }

      if (that.equipmentmarker) {
        this.map.remove(that.equipmentmarker)
      }
      // 获取车辆点
     this.getcarlistdots()
      // this.map.add(this.overlayGroups)
      // 将 Icon 实例添加到 marker 上:
      // var marker = new AMap.Marker({
      //   position: new AMap.LngLat(125.7758392516627, 41.677211577433326),
      //   // position: new AMap.LngLat(116.405467, 39.907761),
      //   offset: new AMap.Pixel(0, 0),
      //   icon: icon, // 添加 Icon 实例
      //   title: '',
      //   zIndex: 10000
      // })
      // marker.on('click', function (e) {
      //   let obj = {
      //     equipmentType: 'car'
      //   }
      //   // that.$refs.editFeature.editFeature(e.lnglat)
      //   that.$refs.markInfo.openEquipmentInfo(obj, e.lnglat)
      // })
      // 人员
      this.getuserListdots()
      // 泄漏报警
      that.Equipmentdot()
      // this.equipmentList
      that.map.add([that.overlayGroups, that.marker2, that.equipmentmarker]);
    },
    /**
     * 车辆点
     */
    getcarlistdots(){
      let that  = this
       // 车辆
      let imgUrl = require('@/assets/car.png')
      var icon = new AMap.Icon({
        size: new AMap.Size(40, 50),    // 图标尺寸
        image: imgUrl,  // Icon的图像
        imageOffset: new AMap.Pixel(0, 0),
        imageSize: new AMap.Size(40, 50)   // 根据所设置的大小拉伸或压缩图片
      })
      let group = []
      for (var i = 0; i < this.carlist.length; i++) {
        if (that.carlist[i].lng && that.carlist[i].lng != 'null') {
          let extData = this.carlist[i]
          let poly = new AMap.Marker({
            position: new AMap.LngLat(this.carlist[i].lng, this.carlist[i].lat),
            // position: this.carlist[i].point,
            offset: new AMap.Pixel(-25, -13),
            icon: icon,
            zIndex: 10000,
            extData
          })
          group.push(poly)
        } else {
          let extData = this.carlist[i]
          extData.lng = that.mapConfig.center[0]
          extData.lat = that.mapConfig.center[1]
          let poly = new AMap.Marker({
            position: new AMap.LngLat(that.mapConfig.center[0], that.mapConfig.center[1]),
            // position: this.carlist[i].point,
            offset: new AMap.Pixel(-25, -13),
            icon: icon,
            zIndex: 10000,
            extData
          })
          group.push(poly)
        }
      }
      this.overlayGroups = new AMap.OverlayGroup(group)
      that.overlayGroups.on('click', async function (e) {
        let obj = {
          equipmentType: 'car',
        }
        obj = { ...obj, ...e.target.getExtData() }
        if(!obj.lng){
          obj.lng = that.mapConfig.center[0]
          obj.lat = that.mapConfig.center[1]
        }
        e.lnglat.lat = obj.lat
        e.lnglat.lng = obj.lng
        await that.getAdrss(obj.lng, obj.lat, obj, e.lnglat)
        await that.getTrajectory(obj.id)
      })
    },
    /**
     * 获取人员点
     */
    getuserListdots(){
      let that = this
      let imgUrl2 = require('@/assets/user.png')
      var icon2 = new AMap.Icon({
        size: new AMap.Size(80, 50),    // 图标尺寸
        image: imgUrl2,  // Icon的图像
        imageOffset: new AMap.Pixel(0, 0),
        imageSize: new AMap.Size(40, 50)   // 根据所设置的大小拉伸或压缩图片
      })

      // 将 Icon 实例添加到 marker 上:
      // var marker2 = new AMap.Marker({
      //   position: new AMap.LngLat(125.78264312177788, 41.675810528528118),
      //   // position: new AMap.LngLat(116.405467, 39.907761),
      //   offset: new AMap.Pixel(0, 0),
      //   icon: icon2, // 添加 Icon 实例
      //   zIndex: 10000
      // })
      // marker2.on('click', function (e) {
      //   let obj = {
      //     equipmentType: 'user'
      //   }
      //   that.$refs.markInfo.openEquipmentInfo(obj, e.lnglat)
      // })

      let usergroup = []
      for (var i = 0; i < that.userlist.length; i++) {
        let extData = that.userlist[i]
        if (that.userlist[i].lng && that.userlist[i].lng != 'null') {
          let poly = new AMap.Marker({
            position: new AMap.LngLat(that.userlist[i].lng, that.userlist[i].lat),
            offset: new AMap.Pixel(0, 0),
            icon: icon2,
            zIndex: 10000,
            extData
          })
          usergroup.push(poly)
        }
      }
      that.marker2 = new AMap.OverlayGroup(usergroup)
      that.marker2.on('click', async function (e) {
        let obj = {
          equipmentType: 'user',
        }
        obj = { ...obj, ...e.target.getExtData() }
        e.lnglat.lat = obj.lat
        e.lnglat.lng = obj.lng
        await that.getuserTrajectory(obj.id)
        await that.getAdrss(obj.lng, obj.lat, obj, e.lnglat)
      })
    },
    /**
     * 获取人员列表
     */
    getuserLists() {
      getbiUserLists().then(res => {
        if (res.code == 200) {
          this.userlist = res.data
        }
      })
    },
    /**
     * 获取车辆列表
     */
    async getcarLists() {
      let res = await getlistCarInfo()
      if (res.code == 200) {
        this.carlist = res.data
      }
    },
    /**
     * 获取泄漏报警（管网的）
     */
    async getEquipmentList() {
      let pageParams = {
        current: 0,
        size: -1,
        equipmentType: 4,
        installType: 2
      }
      scadaApi.getEquipmentLists(pageParams).then(res => {
        if (res.code == 200) {
          this.equipmentList = res.data.list
        }
      })
    },
    /**
     * 泄漏报警（插点）
     */
    Equipmentdot() {
      let that = this
      let imgUrl3 = require('@/assets/gis/map/90031.png')
      var icon3 = new AMap.Icon({
        size: new AMap.Size(80, 50),    // 图标尺寸
        image: imgUrl3,  // Icon的图像
        imageOffset: new AMap.Pixel(0, 0),
        imageSize: new AMap.Size(24, 24)   // 根据所设置的大小拉伸或压缩图片
      })
      let usergroup = []
      for (var i = 0; i < that.equipmentList.length; i++) {
        let extData = that.equipmentList[i]
        // extData.pointArray = []
        let pointArray = []
        if (that.equipmentList[i].point) {
          let str = (that.equipmentList[i].point).slice(6, -1)
          let arr = str.split(' ')
          pointArray = arr
        }
        let point = pointArray
        if (point && point.length) {
          let poly = new AMap.Marker({
            position: new AMap.LngLat(point[0], point[1]),
            offset: new AMap.Pixel(0, 0),
            icon: icon3,
            zIndex: 10000,
            extData
          })
          usergroup.push(poly)
        }
      }
      that.equipmentmarker = new AMap.OverlayGroup(usergroup)
      that.equipmentmarker.on('click', async function (e) {
        let obj = {
          equipmentType: 'revealpoint',
        }
        obj = { ...obj, ...e.target.getExtData() }
        // await that.getuserTrajectory(obj.id)
        await that.$refs.StationDesc.openWarn(obj)
        // await that.getAdrss(obj.lng,obj.lat,obj,e.lnglat)
      })
    },
    initMap() {
      let that = this
      this.companyId = localStorage.getItem('zlCompanyId')
      this.getcarLists()
      this.getuserLists()
      this.getEquipmentList()
      if (that.companyId != 1) {
        that.mapConfig.zoom = 12
      }
      console.log(that.mapConfig)
      that.mapConfig.mapStyle='amap://styles/a846b3e29b7998c7e7ba114be0953e66'
      // let map = new AMap.Map('amapStation', {
      //   ...that.mapConfig
      // })
      window.echartMap = this.$echarts.init(document.getElementById('amapStation'))
      let options = {
        amap: {
          ...that.mapConfig
        },
        series: [{
              type: 'lines',
              coordinateSystem: 'amap',
              polyline: true,
              data: [],
              lineStyle: {
                width: 0
              },
              effect: {
                constantSpeed: 20,
                show: true,
                trailLength: 0.01,
                symbolSize: 7,
                color: 'rgba(255,255,0, 0.6)'
              },
              zlevel: 2
            }]
      }     
      window.echartMap.setOption(options, true)
      var map = window.echartMap.getModel().getComponent('amap').getAMap();
      that.map = map
      map.addControl(new AMap.Scale({
        position: {
          bottom: '12px',
          left: '120px'
        }
      }))

      that.map.on('complete', function () {
        that.$refs.zoom.init()
        //  that.getMark()
        that.drawEnd()
        // that.$refs.topTool.endToolDraw()
        that.loadStateSet = that.loadStateSet + 1
        // that.map.setDefaultCursor('darkblue')
        that.getwarnIng()
        // that.$refs.rightTool.MapChangeFun("darkblue")
     //   that.$refs.rightTool.MapChangeFun(this.mapStyle)
        if (that.warnList.length) {
          that.setXlbjMarker(that.warnList)
        }
        that.timer = setInterval(() => {
          that.getwarnIng()
        }, 120000)
        that.getMarkStation(1)
        // 添加车点击事件 && 1通化中联 非1四平
        // if (that.companyId == 1) {
          that.setCarMarker()
        // }
        that.simulationFun()
      })

      that.map.on('mousemove', e => {
        let lnglat = e.lnglat
        if (that.isEdit) {
          that.mouseHover(e)
        }
        that.AdAnalysis(lnglat, e)

        if (that.drawType == 'rule') {
          let length = that.ruleObj.length
          if (length > 0) {
            let l = AMap.GeometryUtil.distanceOfLine([...that.ruleObj, lnglat])
            that.ruleM = l.toFixed(1)
            that.mouseHover(e)
          }
        }
      })
      that.map.on('moveend', e => {
        this.$nextTick(() => {
          if (that.zoom < that.maxZoom) {
          } else {
            that.getBoundsFeat(that.queryLayer)
          }
        })
      })
      that.map.on('click', e => {
        if(this.simulationState){
          return
        }
        console.log(e)
        let that = this;
        that.$refs.editFeature.clear()
        that.$refs.editFeature.editFeature(e.lnglat)




      })
      that.map.on('rightclick', e => {
        if (!that.isEdit) {
          that.$refs.editFeature.changeLayer(e.lnglat)
        }
      })
      // that.map.on('mousedown', e => {
      //   if (that.drawState) {
      //     //绘制提示信息
      //     that.onClick(e)
      //   }
      // })

      //工具
      that.mouseTool = new AMap.MouseTool(that.map)
      // 管线设备工具条
      that.editTool = new AMap.MouseTool(that.map)
      // 安全设备工具条
      that.equipmentTool = new AMap.MouseTool(that.map)
      //   that.getMark() 默认显示
      //that.$refs.rightTool.MapChangeFun("14b21bce7a575d0538b32156ce755dae")
      that.$refs.rightTool.MapChangeFun(this.mapStyle)

    },
    openQuery() {
      this.$refs.Childmain.openQuery() //打开综合查询页面
    },
    AdAnalysis(lnglat, e) {
      let that = this
      if (
        that.queryVectorObj &&
        that.layerName &&
        that.$store.getters.activeOptionItem == 'adsorption'
      ) {
        let distanceP = null
        let distanceL = null
        let feature = {}
        let maxlength = 10
        let point = {}
        let line = {}
        that.queryVectorGeo.forEach(item => {
          if (item.geometry.type == 'Point') {
            if (
              (item.properties.node_type2 == constantDict.jiedian.dictCode &&
                that.layerName == commonLayer['pressure']) ||
              that.layerName != commonLayer['pressure']
            ) {
              let path = formatLocation(item.properties.location_str, 'Point')
              let l = Math.round(AMap.GeometryUtil.distance(lnglat, path))
              if (!distanceP || distanceP > l) {
                distanceP = l
                item.l = l
                point = item
              }
            }
          } else {
            let path = formatLocation(item.properties.location_str, 'LineString')
            let l = Math.round(AMap.GeometryUtil.distanceToLine(lnglat, path))
            if (!distanceL || distanceL > l) {
              distanceL = l
              item.l = l
              line = item
            }
          }
        })
        if (point.l && line.l) {
          let d = point.l - line.l
          if (d > 5 && line) {
            feature = line
          } else {
            feature = point
          }
        } else {
          feature = point.geometry ? point : line
        }
        if (feature.properties) {
          let bounds = that.map.getBounds()
          let northEast = bounds.northEast //东北角 southWest
          let southWest = bounds.southWest
          let pixel = e.pixel
          let px = Math.sqrt(pixel.x * pixel.x + pixel.y * pixel.y)
          let pointObj = null
          if (feature.geometry.type == 'Point') {
            pointObj = formatLocation(feature.properties.location_str, 'Point')
          } else {
            let path = formatLocation(feature.properties.location_str, 'LineString')
            pointObj = AMap.GeometryUtil.closestOnLine(lnglat, path)
          }
          let m = Math.round(AMap.GeometryUtil.distance([southWest.lng, northEast.lat], pointObj))

          let m2 = Math.round(AMap.GeometryUtil.distance(lnglat, pointObj))
          //距离小于规定像素
          if (m2 * (px / m) < maxlength) {
            that.queryVectorObj.setPosition(pointObj)
            feature.pointObj = pointObj
            that.queryFeatrue = feature
          } else {
            that.queryVectorObj.setPosition(lnglat)
            that.queryFeatrue = null
          }
        } else {
          that.queryVectorObj.setPosition(lnglat)
          that.queryFeatrue = null
        }
      } else if (that.queryVectorObj && that.queryType) {
        that.queryVectorObj.setPosition(lnglat)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.gis-main {
  position: relative;
}

::v-deep .amap-ranging-label {
  background: var(--background-color-skin);
  border: 1px solid var(--font-color-b1);
}

.layer-control {
  // float: left;
  background: var(--background-color-skin);
  height: calc(100vh - 150px);
  color: var(--font-color-f1);
  position: relative;


  .layer {
    width: 250px;
  }


}

.top-tool {
  position: absolute;
  z-index: 9;
  margin-left: 10px;
  margin-top: 11px;
  height: 10px;
}

.map-size {
  text-align: center;

  width: 100%;
  height: calc(100vh);
}

#amapStation {
  width: 100%;
  height: 100%;
}

.view {
  position: relative;
  flex: 1;
  color: var(--font-color-f1);
  // margin-left: 250px;
  width: auto;
}

// ::v-deep .amap-scalecontrol {
//   background: none !important;
//   left: 0.7rem !important;
//   bottom: 0.1rem !important;
//   transition: left 0.5s;
// }
::v-deep .amap-scalecontrol {
  background: none !important;
  left: 2px !important;
  bottom: 10px !important;
  transition: left 0.5s;
}

::v-deep .drawDel {
  color: #01d8ff;
  font-size: 18px;
}

::v-deep.deleteNode {
  content: url(./image/rule-remove.png);
}

.drawTipmouse {
  position: absolute;
  top: -100px;
  left: 0;
  z-index: 8;
  background: var(--background-color-skin);
  border-radius: 5px;
  color: var(--font-color-f1);
  padding: 5px 10px;
  white-space: nowrap;
}
</style>
