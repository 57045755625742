<template>
  <div>
    <el-dialog
      :title="addOrEdit == 'add' ? '新增泄漏报警器' : '编辑泄漏报警器'"
      :visible.sync="dialogVisible"
      :inline="true"
      :modal="false"
      :close-on-click-modal="false"
      :width="form.warningType == 1 ? '1080px' : '630px'"
      custom-class="dialog-class"
      @close="close"
    >
      <el-form
        v-if="dialogVisible"
        ref="form"
        :rules="rule"
        :model="form"
        label-width="100px"
        :inline="true"
        label-position="right"
        size="mini"
      >
        <div class="warn-form">
          <div class="warn-left">
            <el-form-item label="所属企业名称:">
              <el-input
                v-model="form.companyName"
                placeholder="请输入所属企业名称"
                disabled
                :style="labelWidthMax"
              ></el-input>
            </el-form-item>
            <el-form-item label="设备编号:">
              <el-input
                v-model="form.warningCode"
                placeholder="请输入设备编号"
                maxlength="40"
                :style="labelWidthMax"
              ></el-input>
            </el-form-item>
            <el-form-item label="设备名称:" prop="warningName">
              <el-input
                v-model="form.warningName"
                placeholder="请输入设备名称"
                :style="labelWidthMax"
              ></el-input>
            </el-form-item>
            <el-form-item label="设备类型:">
              <el-select
                v-model="type"
                popper-class="gis-default"
                disabled
                placeholder="请选择设备类型"
                :style="labelWidth"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备条码:" prop="barCode">
              <el-input
                v-model="form.barCode"
                placeholder="请输入设备条码"
                :style="labelWidth"
              ></el-input>
            </el-form-item>
            <el-form-item label="报警器类型:" prop="warningType">
              <el-select
                v-model="form.warningType"
                popper-class="gis-default"
                placeholder="请选择报警器类型"
                :style="labelWidth"
              >
                <el-option
                  v-for="item in warningTypeDict"
                  :key="item.dictCode"
                  :label="item.name"
                  :value="item.dictCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="分类一:">
              <el-select
                v-model="form.warningKind"
                popper-class="gis-default"
                placeholder="请选择分类一"
                :style="labelWidth"
              >
                <el-option
                  v-for="item in warningKindDict"
                  :key="item.dictCode"
                  :label="item.name"
                  :value="item.dictCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="规格:">
              <el-select
                v-model="form.spec"
                popper-class="gis-default"
                placeholder="请选择规格"
                :style="labelWidth"
              >
                <el-option
                  v-for="item in specDict"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="型号:">
              <el-select
                v-model="form.modelCode"
                popper-class="gis-default"
                placeholder="请选择型号"
                :style="labelWidth"
              >
                <el-option
                  v-for="item in modelDict"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="厂商:">
              <el-select
                v-model="form.factory"
                popper-class="gis-default"
                placeholder="请选择厂商"
                :style="labelWidth"
                @change="factoryCodeChge"
              >
                <el-option
                  v-for="item in factoryDict"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="权属单位:" prop="companyId">
              <el-select
                v-model="form.companyId"
                popper-class="gis-default"
                disabled
                placeholder="请选择权属单位"
                :style="labelWidth"
              >
                <el-option
                  v-for="item in companyIdDict"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="是否远传:" prop="transmit">
              <el-select
                v-model="form.transmit"
                popper-class="gis-default"
                placeholder="请选择是否远传"
                :style="labelWidth"
              >
                <el-option
                  v-for="item in optionsAideo"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备状态:" prop="generalStatus">
              <el-select
                v-model="form.generalStatus"
                popper-class="gis-default"
                placeholder="请选择设备状态"
                :style="labelWidth"
              >
                <el-option
                  v-for="item in optionsStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="安装日期:" prop="installDate">
              <el-date-picker
                v-model="form.installDate"
                type="date"
                :clearable="false"
                :editable="false"
                popper-class="datePickerClass"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                :style="labelWidth"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="是否在线:" prop="online">
              <el-select
                v-model="form.online"
                popper-class="gis-default"
                placeholder="请选择是否在线"
                :style="labelWidth"
              >
                <el-option
                  v-for="item in optionsAideo"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="使用协议:">
              <el-input
                v-model="form.useAccord"
                placeholder="请输入使用协议"
                maxlength="100"
                :style="labelWidthMax"
              ></el-input>
            </el-form-item>
            <el-form-item label="经纬度:">
              <el-input
                v-model="form.point"
                disabled
                placeholder="请输入经纬度"
                :style="labelWidthMax"
              ></el-input>
            </el-form-item>
          </div>
          <div v-if="form.warningType == 1" class="warn-right">
            <div class="right-title">
              <div>报警探头</div>
              <div style="cursor: pointer" @click="addList"><i class="el-icon-plus"></i>新增</div>
            </div>
            <div>
              <el-table
                :data="form.list"
                style="width: 100%"
                :header-cell-style="headerStyle"
                :row-style="headerStyle"
              >
                <el-table-column type="index" label="序号" width="50"> </el-table-column>
                <el-table-column prop="cameraName" label="探头名称"> </el-table-column>
                <el-table-column prop="remark" label="备注"> </el-table-column>
                <el-table-column label="操作" align="left" width="120">
                  <template #default="{ row,$index}">
                    <el-button type="text" class="btnText" size="mini" @click="onRowClick(row)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      class="btnText danger"
                      size="mini"
                      @click="deleteCameraList($index)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </el-form>
      <div class="btn">
        <el-button v-if="addOrEdit === 'add'" size="mini" type="primary" @click="submit"
          >保存</el-button
        >
        <el-button v-else size="mini" type="primary" @click="updateData">保存</el-button>
        <el-button size="mini" @click="close">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="!activeList ? '新增报警探头' : '修改报警探头'"
      :visible.sync="listVisible"
      :inline="true"
      :modal="false"
      width="430px"
      custom-class="dialog-class"
      @close="listClose"
    >
      <el-form
        v-if="listVisible"
        ref="listForm"
        :rules="rule"
        :model="listForm"
        label-width="100px"
      >
        <el-form-item label="探头名称:" prop="cameraName">
          <el-input v-model="listForm.cameraName" placeholder="请输入探头名称"></el-input>
        </el-form-item>
        <el-form-item label="备注:" prop="remark">
          <el-input v-model="listForm.remark" maxlength="100" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <div class="btn">
        <el-button size="mini" type="primary" @click="submitList">确定</el-button>
        <el-button size="mini" @click="listVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { saveWarn, update } from '../apis/equipment'
import {
  findDictTree,
  findCmpOptions,
  findManufacturerOptions,
  findTypeOptions,
  findSpecOptions
} from '../apis/commonType'
import { getCompanyName, getCompanyId } from '../utils/auth'
import { commonDict } from '../utils/dict.js'
export default {
  data() {
    return {
      eqType: 31,
      addOrEdit: 'add',
      type: 'warn',
      form: {
        list: [],
        warningType: '',
        transmit: '',
        generalStatus: '',
        installDate: '',
        online: ''
      },
      headerStyle: {
        color: 'rgba(86, 179, 227, 1)',
        'background-color': '#244162',
        'border-bottom': 'rgba(110, 183, 227, 0.2) 1px solid'
      },
      labelWidth: 'width:180px',
      labelWidthMax: 'width:470px',
      listForm: {},
      title: '新增泄漏报警器',
      dialogVisible: false,
      listVisible: false,
      activeList: null,
      options: [
        {
          value: 'warn',
          label: '泄漏报警器'
        }
      ],
      optionsAideo: [
        {
          value: 0,
          label: '否'
        },
        {
          value: 1,
          label: '是'
        }
      ],
      optionsStatus: [
        {
          value: 1,
          label: '启用'
        },
        {
          value: 0,
          label: '停用'
        }
      ],
      warningTypeDict: [],
      warningKindDict: [],
      companyIdDict: [],
      factoryDict: [],
      modelDict: [],
      specDict: [],
      rule: {
        warningName: [
          { required: true, message: '请输入设备名称', trigger: 'change' },
          { max: 100, message: '超出最大限制', trigger: ['change', 'blur'] }
        ],
        barCode: [
          { required: true, message: '请输入设备条码', trigger: 'change' },
          { max: 100, message: '超出最大限制', trigger: ['change', 'blur'] }
        ],
        cameraName: [
          { required: true, message: '请输入探头名称', trigger: 'change' },
          { max: 100, message: '超出最大限制', trigger: ['change', 'blur'] }
        ],
        companyId: [{ required: true, message: '请选择权属单位', trigger: 'change' }],
        warningType: [{ required: true, message: '请选择报警器类型', trigger: 'change' }],
        generalStatus: [{ required: true, message: '请选择设备状态', trigger: 'change' }],
        installDate: [{ required: true, message: '请选择安装日期', trigger: 'change' }],
        transmit: [{ required: true, message: '请选择是否远传', trigger: 'change' }],
        online: [{ required: true, message: '请选择是否在线', trigger: 'change' }]
      }
    }
  },
  async mounted() {
    this.form.companyName = getCompanyName()
    this.form.companyId = Number(getCompanyId())
  },
  methods: {
    getDate() {
      var d = new Date()
      var year = d.getFullYear()
      var month = d.getMonth() + 1
      var date = d.getDate()
      var curDateTime = year + '-'
      if (month > 9) curDateTime = curDateTime + '' + month + '-'
      else curDateTime = curDateTime + '0' + month + '-'
      if (date > 9) curDateTime = curDateTime + '' + date
      else curDateTime = curDateTime + '0' + date
      return curDateTime
    },
    // 获取型号
    getFindTypeOptions(manufacturerCode) {
      findTypeOptions({ eqType: this.eqType, manufacturerCode }).then(res => {
        if (res.data) {
          this.modelDict = res.data
        }
      })
    },
    // 获取规格
    getFindSpecOptions(manufacturerCode) {
      findSpecOptions({ eqType: this.eqType, manufacturerCode }).then(res => {
        if (res.data) {
          this.specDict = res.data
        }
      })
    },
    factoryCodeChge(value) {
      this.$set(this.form, 'model', null)
      this.$set(this.form, 'spec', null)
      if (value) {
        this.getFindTypeOptions(value)
        this.getFindSpecOptions(value)
      }
    },
    openDialog(data) {
      this.addOrEdit = data.addOrEdit
      if (data.addOrEdit === 'add') {
        this.form.point = data.lnglat
        this.getCodeName('add')
      } else {
        this.form = data
        if (this.form.factoryCode) {
          this.getFindTypeOptions(this.form.factory)
          this.getFindSpecOptions(this.form.factory)
        }
        this.getCodeName()
      }
      this.dialogVisible = true
    },
    onRowClick(row, column, event) {
      console.log(row)
      this.activeList = row
      this.listForm = JSON.parse(JSON.stringify(row))
      this.listVisible = true
    },
    // 获取字典
    getCodeName(type) {
      let code = commonDict[100009].code + ',' + commonDict[100013].code
      findDictTree({ dictCode: code }).then(res => {
        if (res.data) {
          this.warningKindDict = res.data[commonDict[100009].code]
          //拼接字典，固定值加字典值
          let getDict = res.data[commonDict[100013].code]
          let adddict = {
            id: 668,
            parentId: 665,
            weight: 1,
            name: '集中报警控制器',
            linkCode: null,
            dictCode: '1'
          }
          let realdict = []
          realdict.push(adddict)
          if (getDict) {
            realdict.push(...getDict)
          }
          this.warningTypeDict = realdict
        }
        if (type == 'add') {
          this.form.warningType = this.warningTypeDict[1].dictCode
          this.form.online = this.optionsAideo[1].value
          this.form.generalStatus = this.optionsStatus[0].value
          this.form.transmit = this.optionsAideo[0].value
          this.form.installDate = this.getDate()
          console.log(this.form)
        }
      })
      findCmpOptions({ types: 2 }).then(res => {
        if (res.data) {
          this.companyIdDict = res.data
        }
      })
      findManufacturerOptions({ eqType: this.eqType, companyId: getCompanyId() }).then(res => {
        if (res.data) {
          this.factoryDict = res.data
        }
      })
    },
    addList() {
      this.listVisible = true
    },
    submitList() {
      this.$refs.listForm.validate(valid => {
        if (valid) {
          if (!this.activeList) {
            this.form.list.push(this.listForm)
          } else {
            this.activeList.cameraName = this.listForm.cameraName
            this.$set(this.activeList, 'remark', this.listForm.remark)
          }
          this.listVisible = false
        }
      })
    },
    listClose() {
      this.listForm = {}
      this.activeList = null
    },
    // 关闭
    close() {
      this.form = {
        list: [],
        warningType: ''
      }
      this.dialogVisible = false
      this.$parent.$parent.equipmentTool.close(true)
      this.$parent.$parent.pointHighlightRemove()
      if (this.addOrEdit == 'add') {
        this.$parent.startDrawEquipment({ value: this.type })
      }
    },
    // 提交
    submit() {
      let that = this
      this.$refs.form.validate(valid => {
        if (valid) {
          that.form = {
            ...that.form,
            layerId: this.$store.getters.currentLayer
          }
          saveWarn(that.form)
            .then(res => {
              if (res.code == 200) {
                that.$message.success(res.msg)
                that.$eventBus.$emit('setFilter')
              } else {
                that.$message.error(res.msg)
              }
              that.close()
            })
            .catch(err => {
              that.$message.error(err)
            })
        }
      })
    },
    // 更新
    updateData() {
      let that = this
      this.$refs.form.validate(valid => {
        if (valid) {
          update(that.form)
            .then(res => {
              if (res.code == 200) {
                that.$message.success(res.msg)
              } else {
                that.$message.error(res.msg)
              }
              that.close()
            })
            .catch(err => {
              that.$message.error(err)
            })
        }
      })
    },
    deleteCameraList(index) {
      this.form.list.splice(index, 1)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: #6f8297;
}
/*去除底边框*/
::v-deep.el-table td.el-table__cell {
  border-bottom: 1px solid #244162;
  color: #fff;
}
::v-deep.el-table th.el-table__cell.is-leaf {
  border: 0;
}
.btn {
  text-align: center;
  button {
    margin: 7px 15px 0 15px;
  }
}
.warn-form {
  display: flex;
  .warn-left {
    flex: 1;
  }
  .warn-right {
    flex: 0 0 450px;
    color: #fff;
    border: 1px solid #6eb7e3;
    box-sizing: border-box;
    margin-bottom: 18px;
    .right-title {
      display: flex;
      justify-content: space-between;
      padding: 5px 20px;
    }
  }
}

.btnText {
  color: var(--font-color-f1);
  padding: 4px 5px;
}
/* table按钮颜色 */
.btnText.danger {
  color: #f74b4b;
}
.btnText.danger:hover,
.btnText.danger:focus {
  color: #f78989;
}
</style>
